export class TabModel {
    public title: string;
    public path: string;
}
export const RESELLER_TABS: Array<TabModel> = [
    { title: 'Info', path: '/resellers/:id' },
    { title: 'Sims', path: '/resellers/:id/sims' },
    { title: 'Roles', path: '/resellers/:id/roles' },
    { title: 'Employees', path: '/resellers/:id/employees' },
    { title: 'Companies', path: '/resellers/:id/companies' },
];

export const COMPANY_TABS: Array<TabModel> = [
    { title: 'Info', path: '/companies/:id' },
    { title: 'Sims', path: '/companies/:id/sims' },
    { title: 'Employees', path: '/companies/:id/employees' },
    { title: 'Branches', path: '/companies/:id/branches' },
    { title: 'Usage', path: '/companies/:id/usage' },
    { title: 'Billings', path: '/companies/:id/billings' },
];