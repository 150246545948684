
import { BaseStateModel, DataTableModel, BranchModel, UserModel, CustomerUsageReportModel, ProviderDataUsageReportModel, CompanyModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BillingService } from "../../Services";

export class ProviderDataUsageReportState {
    public providerDataUsageReports: Array<ProviderDataUsageReportModel> = [];
    public companies: Array<CompanyModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        // <PROVIDER>, <DATAUSED>, and <PRICEPERMB_PROVIDER>
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'provider', label: 'PROVIDER', isSort: true },
            { key: 'usage', label: 'DATA USED', isSort: true },
            { key: 'pricePerMb', label: 'PRICEPERMB PROVIDER', isSort: true }
        ]
    }
}
