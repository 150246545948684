import BaseService from './BaseService';
import { Http } from '../Http';
import { Response, GroupModel, Options, ResponseList } from '../Redux/Models';

export default class GroupService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<GroupModel>> {
        let url: string = '/groups';
        if (options?.root) {
            url = options.root + url;
        }
        GroupService.initCancelToken();
        if (options?.source) {
            GroupService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: GroupService.source?.token })
                .then(res => {
                    let data = new ResponseList<GroupModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }


    public static getById(id: string): Promise<Response<GroupModel>> {
        return new Promise((resolve, reject) => {
            Http.get('/groups/' + id).then(resp => {
                let data = new Response<GroupModel>(resp?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                })
        });
    }

    public static update(id: string, params: Object = {}, options: Options = {}): Promise<Response<GroupModel>> {
        this.initCancelToken();
        let url: string = '/groups/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<GroupModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static sendSMS(params: object = {}) {
        return new Promise((resolve, reject) => {
            Http.post('/group/send-sms', params)
                .then(res => {
                    let respData = new Response<any>(res?.data);
                    return resolve(respData);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static assignAutomationRules(params: object = {}) {
        return new Promise((resolve, reject) => {
            Http.post('/automation/automation-group', params)
                .then(res => {
                    let respData = new Response<any>(res?.data);
                    return resolve(respData);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}