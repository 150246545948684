import {
    EmojiObjectsIcon,
    PlayArrowIcon,
    DoubleArrowIcon,
    SmsIcon,
    Settings
} from '../../Helpers/Icons';
import { SimService } from "../../Services";
import { AutomationRuleModel, BaseStateModel, DataTableModel, EventModel, GroupModel, PriceModel, SimDataTrafficModel, SimModel, TagModel, UserModel } from "../Models";
import { DataCardModel } from '../Models/Base.Models';

export class SimListState extends BaseStateModel {
    public sim: Array<SimModel> = [];
    public noData: boolean = false;
    public showModal: boolean = false
    public showSMSModal: boolean = false
    public showAutomationModal: boolean = false
    public loading: boolean = false
    public users: Array<UserModel> = [];
    public tags: Array<TagModel> = [];
    public selectedItems: Array<number> = [];
    public automations: Array<AutomationRuleModel> = [];
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }
    public modal: any = {
        userType: '',
        user: null,
        sims: [],
        automations: []
    }
    public smsModal: any = {
        message: ''
    }

    public actionIcons: any = [
        { key: 'activate', icon: EmojiObjectsIcon, tooltip: 'Activate' },
        { key: 'reactivate', icon: PlayArrowIcon, tooltip: 'Reactivate' },
        { key: 'assign', icon: DoubleArrowIcon, tooltip: 'Assign' },
        { key: 'sms', icon: SmsIcon, tooltip: 'Send SMS' },
        { key: 'automation', icon: Settings, tooltip: 'Automation' }
    ];

    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        selectble: true,
        request: SimService.getList,
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '10%' } },
            { key: 'MSISDN', label: 'MSISDN', isSort: true, style: { width: '15%' } },
            { key: 'IMSI', label: 'IMSI ', isSort: true, style: { width: '10%' } },
            { key: 'thingName', label: 'Name', isSort: true, style: { width: '10%' } },
            { key: 'thingsGroupName', label: 'Group Name', isSort: false, style: { width: '20%' } },
            {
                key: 'data', label: 'Data Usage', isSort: false, style: { width: '10%' }, render: (sim: SimModel) => {
                    return sim.data + " MB";
                }
            },
            { key: 'state', label: 'Status', isSort: true, style: { width: '10%' } },
        ]
    }
}

export class EditSimState extends BaseStateModel {
    // public nameSearch: Array<SimSearchModel> = [];
    // public types = ["THINGSMOBILE"];
    public loading: boolean = false;
    public simuserModel: SimModel = {
        type: '',
        name: '',
        barcode: '',
        msisdn: '',
        apn: '',
        iccid: '',
        tag: '',
    }
}


export class SimDetailsState extends BaseStateModel {
    public loading: boolean = false;
    public showModal: boolean = false;
    public modal: any = {
        from: '',
        message: '',
        sims: [],
        automations: []
    }
    public automations: Array<AutomationRuleModel> = [];
    public automationsBySimId: Array<AutomationRuleModel> = [];
    public events: Array<EventModel> = [];
    public showAutomationModal: boolean = false;
    public simModel: SimModel = {
        type: '',
        name: '',
        barcode: '',
        msisdn: '',
        apn: '',
        iccid: '',
        thingTags: [],
        activationDate: '',
        active: false,
        balance: '',
        blockSimAfterExpirationDate: '',
        blockSimDaily: '',
        blockSimMonthly: '',
        blockSimTotal: '',
        cdrs: '',
        dailyTraffic: '',
        dailyTrafficThreshold: '',
        expirationDate: '',
        monthlyTraffic: '',
        monthlyTrafficThreshold: '',
        plan: {},
        simType: '',
        status: '',
        state: 'ACTIVE',
        totalTraffic: '',
        totalTrafficThreshold: '',
        lockMasterImei: false
    }

    public eventDataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        selectble: false,
        request: () => { },
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '10%' } },
            { key: '_source.message', label: 'Message', isSort: true, style: { width: '15%' } },
            { key: '_source.Severity', label: 'Severity ', isSort: true, style: { width: '10%' } },
            { key: '_source.EsDataType', label: 'Data Type', isSort: true, style: { width: '10%' } },
            { key: '_source.MessageType', label: 'Type', isSort: false, style: { width: '20%' } },
            {
                key: '_source.Timestamp', label: 'Time', isSort: true, style: { width: '20%' }, render: (item: SimDataTrafficModel) => {
                    var date = new Date(item._source.Timestamp);
                    return date.getDate() +
                        "/" + (date.getMonth() + 1) +
                        "/" + date.getFullYear() +
                        " " + date.getHours() +
                        ":" + date.getMinutes() +
                        ":" + date.getSeconds();
                }
            },
        ]
    }

    public ruleDataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        selectble: false,
        request: () => { },
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '10%' } },
            { key: 'automationRuleId', label: 'Rule', isSort: false, style: { width: '50%' }, render: (item: AutomationRuleModel) => item.automationRuleId.name }
        ]
    }

    public groups: Array<GroupModel> = [];
    public plans: Array<PriceModel> = [];
}