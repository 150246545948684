import { Settings } from "@material-ui/icons";
import GroupService from "../../Services/GroupService";
import { AutomationRuleModel, BaseStateModel, DataTableModel, GroupModel, SimModel } from "../Models";

export class GroupListState extends BaseStateModel {
    public groups: Array<GroupModel> = [];
    public showSMSModal: boolean = false;
    public selectedId: string | number | null = null;
    public loading: boolean = false;
    public noData: boolean = false;
    public showAutomationModal: boolean = false;
    public automations: Array<AutomationRuleModel> = [];
    public selectedItems: Array<number> = [];
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }
    public smsModal: any = {
        groupId: '',
        message: ''
    }
    public actionIcons: any = [
        { key: 'automation', icon: Settings, tooltip: 'Automation' }
    ];
    public modal: any = {
        userType: '',
        user: null,
        groups: [],
        automations: []
    }
    public dataTable: DataTableModel = {
        pagination: false,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '20%',
        selectble: true,
        request: GroupService.getList,
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '20%' } },
            { key: 'thingsGroupName', label: 'Name', isSort: true, style: { width: '20%' } },
            { key: 'active', label: 'Active', isSort: true, style: { width: '20%' } },
            { key: 'total', label: 'Total', isSort: true, style: { width: '20%' } },
            {
                key: 'data', label: 'Data Usage', isSort: true, style: { width: '20%' }, render: (item: GroupModel) => {
                    const n = item.data.valueOf()
                    const result = (n / 1048576).toFixed(2)
                    return result + ' MB'
                }
            }
        ]
    }
}

export class GroupDetailsState extends BaseStateModel {

    public loading: boolean = false;
    public group: GroupModel = new GroupModel();
    public otherDetails: Array<SimModel> = [];

    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        selectble: true,
        request: () => { },
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '10%' } },
            { key: 'MSISDN', label: 'MSISDN', isSort: true, style: { width: '15%' } },
            { key: 'IMSI', label: 'IMSI ', isSort: true, style: { width: '10%' } },
            { key: 'thingName', label: 'Name', isSort: true, style: { width: '10%' } },
            { key: 'thingsGroupName', label: 'Group Name', isSort: false, style: { width: '20%' } },
            { key: 'state', label: 'Status', isSort: true, style: { width: '20%' } },
        ]
    }
}