import { PriceService } from "../../Services";
import { BaseStateModel, DataTableModel, PlanModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';


export class PlanListState extends BaseStateModel {
    public plans: Array<PlanModel> = [];
    public dataTable: DataTableModel = {
        pagination: false,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '20%',
        actions: [
            { key: 'view', icon: VisibilityIcon },
        ],
        request: PriceService.getList,
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '40%' } },
            { key: 'name', label: 'Name', isSort: true, style: { width: '50%' } }
        ]
    }
}

export class EditPlanState extends BaseStateModel {
    public loading: boolean = false;
    public plan: PlanModel = {
        name: '',
        cost: 0
    };
}

export class PlanViewState extends BaseStateModel {
    public loading: boolean = false;
    public plan: PlanModel = {
        name: '',
        cost: 0
    };
}