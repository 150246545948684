import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'

export class InstaIcon extends Component<Props, States> {
    static defaultProps = {
        width: 16,
        height: 16
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.type === 'light' ? theme.colors.purple : theme.colors.orange;

        return (
            <SVGContainer {...restProps}>
                <svg width={width} height={height} style={{ color: color }} viewBox="0 0 19 19" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.415527 5.17999C0.415527 2.41857 2.6541 0.179993 5.41553 0.179993H13.4155C16.177 0.179993 18.4155 2.41857 18.4155 5.17999V13.18C18.4155 15.9414 16.177 18.18 13.4155 18.18H5.41553C2.6541 18.18 0.415527 15.9414 0.415527 13.18V5.17999ZM5.41553 2.17999C3.75867 2.17999 2.41553 3.52314 2.41553 5.17999V13.18C2.41553 14.8368 3.75867 16.18 5.41553 16.18H13.4155C15.0724 16.18 16.4155 14.8368 16.4155 13.18V5.17999C16.4155 3.52314 15.0724 2.17999 13.4155 2.17999H5.41553Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9155 5.67999C14.4678 5.67999 14.9155 5.23228 14.9155 4.67999C14.9155 4.12771 14.4678 3.67999 13.9155 3.67999C13.3632 3.67999 12.9155 4.12771 12.9155 4.67999C12.9155 5.23228 13.3632 5.67999 13.9155 5.67999ZM7.41553 9.17999C7.41553 8.07542 8.31096 7.17999 9.41553 7.17999C10.5201 7.17999 11.4155 8.07542 11.4155 9.17999C11.4155 10.2846 10.5201 11.18 9.41553 11.18C8.31096 11.18 7.41553 10.2846 7.41553 9.17999ZM9.41553 5.17999C7.20639 5.17999 5.41553 6.97085 5.41553 9.17999C5.41553 11.3891 7.20639 13.18 9.41553 13.18C11.6247 13.18 13.4155 11.3891 13.4155 9.17999C13.4155 6.97085 11.6247 5.17999 9.41553 5.17999Z" fill="currentColor" />
                </svg>
            </SVGContainer>
        );
    }
}

export default withTheme(InstaIcon);
