
import { BaseStateModel, DataTableModel, BranchModel, UserModel, CustomerUsageReportModel, DataUsageTrendReportModel, DeviceUsageReportModel, CustomerProviderPreferenceReportModel, CostAnalysisReportModel, CountrywiseTotalConsumptionReportModel, CompanyModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BillingService } from "../../Services";

export class CountrywiseTotalConsumptionReportState {
    public dataUsageReports: Array<CountrywiseTotalConsumptionReportModel> = [];
    public companies: Array<CompanyModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        // <TOTALCOUNTRYCONSUMPTION> and <COUNTRY>
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'totalConsumption', label: 'TOTALCOUNTRYCONSUMPTION', isSort: true },
            { key: 'country', label: 'COUNTRY', isSort: true }
        ]
    }
}
