import React, { Component } from 'react'

export class Fallback extends Component<any, any> {
    render() {
        return (
            <div className="fallback flex-center">
                <p>Loading....</p>
            </div>
        )
    }
}

export default Fallback
