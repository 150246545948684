import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

export const InputValue = styled.input`
    height: 2.5rem;
    width: 100%;
    padding: 0 0.5rem;
    border: 1px solid rgb(0 0 0 / 26%);
    border-radius: 1rem;
    outline: none;
    color: rgba(0, 0, 0, 0.54);
    vertical-align: middle;
    display: table-cell;
`;

export const PlaceholderContainer = styled.label`
    position: absolute;
    top: -2rem;
    bottom: 0;
    left: 0;
    right: 0;
    border: 3px solid transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    pointer-events: none;
    font-size: 1.4rem;
    padding: 0 0.5rem;
`;

export const PlaceholderValue = styled.div`
    padding: 0 0.5rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.9rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
`;