import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'

export class Twitter2Icon extends Component<Props, States> {
    static defaultProps = {
        width: 14,
        height: 12
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.type === 'light' ? theme.colors.purple : theme.colors.orange;

        return (
            <SVGContainer {...restProps}>
                <svg width={width} height={height} style={{ color: color }} viewBox="0 0 21 17" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.714228 10.1285C1.07864 9.5389 1.72237 9.17999 2.41553 9.17999C2.60017 9.17999 2.82017 9.17975 3.06427 9.17409C2.86678 8.84872 2.69667 8.50514 2.55498 8.14587C1.8527 6.36508 1.90049 4.34532 2.50968 2.43073C2.76776 1.6196 3.51097 1.0607 4.36186 1.03786C5.21275 1.01501 5.98487 1.53324 6.2861 2.32935C6.62234 3.21799 7.03104 3.56848 7.49436 3.77946C7.78984 3.91401 8.16004 4.01444 8.63888 4.08109C9.30159 1.79012 11.3702 0.179993 13.9155 0.179993C14.6645 0.179993 15.3758 0.356254 15.9623 0.590266C16.2015 0.685707 16.4437 0.800315 16.6781 0.933561L18.0982 0.705332C18.8094 0.591029 19.5271 0.86802 19.9772 1.43048C20.4272 1.99294 20.54 2.75395 20.2725 3.42277L19.4116 5.5751C19.3424 8.98638 18.3702 11.6788 16.5172 13.5317C14.5975 15.4515 12.0222 16.18 9.41553 16.18C7.71244 16.18 5.95239 15.8988 4.42275 15.2869C2.91743 14.6848 1.4258 13.6727 0.626675 12.0744C0.316687 11.4544 0.349815 10.7182 0.714228 10.1285ZM17.4155 5.17999L18.4155 2.67999L16.1933 3.03714C16.1759 3.02033 16.1577 3.00353 16.1388 2.98675C15.6749 2.57562 14.7696 2.17999 13.9155 2.17999C11.9155 2.17999 10.4155 3.67999 10.4155 5.67999V6.17999C9.69045 6.17999 9.02378 6.15078 8.41553 6.06917C7.48842 5.94478 6.69703 5.69864 6.04136 5.24862C5.34738 4.77232 4.80544 4.06762 4.41553 3.03714C4.06241 4.14695 3.95867 5.23896 4.10434 6.23137C4.25507 7.25836 4.67288 8.1787 5.35776 8.90176C5.88526 9.45866 6.57118 9.89854 7.41553 10.18C6.70907 10.651 5.78078 10.9001 4.89186 11.0319C4.85401 11.0375 4.81623 11.0429 4.77853 11.0481C3.82204 11.18 2.9245 11.18 2.41553 11.18C2.81538 11.9797 3.53498 12.6195 4.44649 13.0994C5.81462 13.8198 7.61507 14.18 9.41553 14.18C13.9155 14.18 17.4155 11.68 17.4155 5.17999Z" fill="currentColor" />
                </svg>
            </SVGContainer>
        );
    }
}

export default withTheme(Twitter2Icon);
