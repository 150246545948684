
import Mixin from '../../../Mixin';
import { Filters, MainBody, InputField } from '../../../Components';
import { BillingListState } from '../../../Redux/States';
import { Props, AutomationRuleModel, States } from '../../../Redux/Models';
import { CircularProgress, IconButton, Tooltip, Modal, CardHeader, Typography, Card, Button, CardContent, CardActions } from '@material-ui/core';
import { VisibilityIcon, DeleteIcon } from '../../../Helpers/Icons';
import { AutomationService, BillingService } from '../../../Services';
import { Row, Col } from 'reactstrap';
import DataTable from '../../../Components/DataTable/DataTable';
import { TableHolder } from '../../../Helpers/Styled';
import { createRef, RefObject, FormEvent, ChangeEvent } from 'react';
import { MODULES, PERMISSIONS } from '../../../Configs/Constants';
import { BillingModel } from '../../../Redux/Models/Billing.Models';
import { CancelTokenSource } from 'axios';
import { StyledCardAndDetailsGrid, StyledCardGrid } from './style';
import classNames from 'classnames';

class BillingListView extends Mixin {
    public state: BillingListState = new BillingListState();
    public dataTable: RefObject<DataTable<BillingModel>>;
    public filters = [];

    constructor(props: Props) {
        super(props);
        this.dataTable = createRef();
        this.state.dataTable.request = this.getBillingList

    }

    public getBillingList = (param: Object, soruce: CancelTokenSource) => {
        const { match: { params } } = this.props;
        if (params.company) {
            return BillingService.getListByCompany(params.company, param, { root: 'resellers/' + params.reseller, sources: soruce });
        } else {
            return BillingService.getList();
        }
    }

    public componentWillUnmount() {
        BillingService.cancelRequestIfPending();
    }

    public onSearch = (param: any) => {
        this.setState((prevState: States) => ({
            params: {
                ...prevState.params,
                q: param.q,
            }
        }), () => {
            this.getCreditUsage({
                ...this.state.params,
                active: 'createdAt',
                direction: 'desc',
            }, true);
        });
    }

    componentDidMount(): void {
        this.getCreditUsage({
            ...this.state.params,
            page: 0,
            active: 'createdAt',
            direction: 'desc',
        }, true);
        this.scrollPager();
    }

    // Infinite Scrolling
    public scrollPager(): void {
        document.body.onscroll = () => {
            if (window.scrollY >= (document.body.scrollHeight - document.body.clientHeight)) {
                if (((this.state.params.page * this.state.params.limit) < this.state.params.total) && (10 < this.state.params.total)) {
                    this.getCreditUsage({
                        ...this.state.params,
                        active: 'createdAt',
                        direction: 'desc',
                    });
                }
            }
        }
    }

    public getCreditUsage = (param?: Object, reset: boolean = false, reqParams: any = false) => {
        const { match: { params } } = this.props;
        this.setState({ noData: false });
        if (params.id) {
            BillingService.getListByCompany(params.id, param, { root: 'resellers/' + params.reseller }).then((resp) => {
                this.setState({ noData: false });
                if (resp.getData().items.length === 0) {
                    this.setState({ noData: true });
                }
                if (!reset) {
                    this.setState({
                        credits: [...this.state.credits, ...resp.getData().items],
                        params: reqParams ? reqParams : {
                            total: resp.getData().total,
                            active: 'createdAt',
                            direction: 'desc',
                            page: this.state.params.page + 1,
                            limit: 10,
                            q: ''
                        }
                    });
                } else {
                    this.setState({
                        credits: resp.getData().items,
                        params: reqParams ? reqParams : {
                            total: resp.getData().total,
                            active: 'createdAt',
                            direction: 'desc',
                            page: 0,
                            limit: 10,
                            q: ''
                        }
                    });
                }

            }).catch(e => this.setState({ noData: true }));
        } else {
            BillingService.getList(param).then((resp) => {
                this.setState({ noData: false });
                if (resp.getData().items.length === 0) {
                    this.setState({ noData: true });
                }
                if (!reset) {
                    this.setState({
                        credits: [...this.state.credits, ...resp.getData().items],
                        params: reqParams ? reqParams : {
                            total: resp.getData().total,
                            active: 'createdAt',
                            direction: 'desc',
                            page: this.state.params.page + 1,
                            limit: 10,
                            q: ''
                        }
                    });
                } else {
                    this.setState({
                        credits: resp.getData().items,
                        params: reqParams ? reqParams : {
                            total: resp.getData().total,
                            active: 'createdAt',
                            direction: 'desc',
                            page: 0,
                            limit: 10,
                            q: ''
                        }
                    });
                }

            }).catch(e => this.setState({ noData: true }));
        }
    }

    public render() {
        const { match: { params } } = this.props;
        const { credits, noData } = this.state;
        // const addUrl = `${params.reseller ? `/resellers/${params.reseller}` : ``}/automation/new`
        // onAddPress={this.user.hasPermission(MODULES.BILLINGS, PERMISSIONS.READ_WRITE) && !params.company ? () => this.props.history.goBack() : false}

        return (
            <MainBody {...this.props} onBackPress={() => this.props.history.goBack()} >
                <div className="d-flex justify-content-between align-items-center mb-5">
                    <Typography variant='h1' className="with-underline">Usage</Typography>
                    <Filters onSearch={this.onSearch} />
                </div>
                <StyledCardAndDetailsGrid className={classNames({ 'is-details': Boolean(false) })}>
                    <StyledCardGrid>
                        {credits.length === 0 && !noData ?
                            (<>Please Wait ...</>) :
                            (noData ?
                                (<>No Data Found</>) : (credits.map(((credit: BillingModel, index: number) => <Card key={index}>
                                    <CardHeader title={credit.MSISDN} />
                                    <CardContent>
                                        <Typography className='small-text' paragraph>{credit.thingName}</Typography>
                                        <Typography className='small-text' paragraph>{credit.sim.plan.plan.name}</Typography>
                                        <Typography className='small-text' paragraph>{credit.usage} Bytes</Typography>
                                        <Typography className='small-text' paragraph>{credit.cost} Cost</Typography>
                                    </CardContent>
                                </Card>
                                ))))}
                    </StyledCardGrid>
                </StyledCardAndDetailsGrid>
            </MainBody>
        );
    }

}

export default BillingListView