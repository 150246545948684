import { ChangeEvent, Component, MouseEvent, FocusEvent } from 'react'
import { Props, States } from '../../Redux/Models';
import { TextField } from '@material-ui/core'
import { EditIcon, SaveIcon } from '../../Helpers/Icons';
import PencilIcon from '../Icons/PencilIcon';
import { IconButton, InputHolder, InputActions } from '../../Helpers/Styled';

export default class InputField extends Component<Props, States> {
    public state: States = {
        editable: false,
        disabled: false
    };

    public constructor(props: Props) {
        super(props);
        this.state.editable = !props.disabled && !!props.editable;
        this.state.disabled = !props.disabled && !!props.editable;
    }

    public static defaultProps = {
        className: "w-100",
        error: false,
        helperText: "",
        label: "input",
        name: "Input",
        onChange: (event: ChangeEvent<HTMLInputElement>) => { },
        select: false,
        value: "",
        editable: false,
        onSave: (v: string) => { }
    }

    public onEditClick = (e: MouseEvent<HTMLButtonElement>) => {
        this.setState((prevState: States) => prevState.disabled = false);
    };

    public onSaveClick = (e: MouseEvent<HTMLButtonElement>) => {
        const { onSave } = this.props;
        this.setState((prevState: States) => prevState.disabled = true);
        if (onSave) {
            onSave();
        }
    };

    public handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
        this.setState((prevState: States) => prevState.disabled = true);
    };

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.editable !== this.props.editable) {
            this.setState((prevState: States) => prevState.disabled = true);
            this.setState((prevState: States) => prevState.editable = this.props.editable);
        }
    }

    public render() {
        const { editable: a, children, className, onSave, ...props } = this.props;
        const { editable, disabled } = this.state;
        return editable ? (
            <InputHolder className={className}>
                <InputActions className="input-actions">
                    {disabled && <IconButton onClick={this.onEditClick}><PencilIcon height={20} width={20} /></IconButton>}
                    {!disabled && <IconButton onClick={this.onSaveClick}><SaveIcon fontSize="small" /></IconButton>}
                </InputActions>
                {children ? (
                    <TextField variant="outlined" {...props} disabled={disabled} fullWidth>{children}</TextField>
                ) : (
                    <TextField variant="outlined" {...props} disabled={disabled} fullWidth />
                )}
            </InputHolder>
        ) : (
            children ? (<TextField variant="outlined" {...props} fullWidth>{children}</TextField>) : (<TextField {...props} fullWidth />)
        );
    }
}
