import PauseOutlinedIcon from '@material-ui/icons/PauseOutlined';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PollIcon from '@material-ui/icons/Poll';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LayersIcon from '@material-ui/icons/Layers';
import LabelRoundedIcon from '@material-ui/icons/LabelRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import BatteryChargingFullRoundedIcon from '@material-ui/icons/BatteryChargingFullRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import SimCardIcon from '@material-ui/icons/SimCard';
import SmsIcon from '@material-ui/icons/Sms';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Settings } from '@material-ui/icons';

export {
    EditIcon,
    VisibilityIcon,
    DeleteIcon,
    ArrowBackIcon,
    AddIcon,
    PhotoCamera,
    FilterListIcon,
    SearchIcon,
    DashboardIcon,
    NotificationsNoneIcon,
    ExpandMoreIcon,
    MenuIcon,
    SaveIcon,
    CloseIcon,
    EmojiObjectsIcon,
    PlayArrowIcon,
    LockRoundedIcon,
    BatteryChargingFullRoundedIcon,
    TodayRoundedIcon,
    LabelRoundedIcon,
    LayersIcon,
    LibraryAddIcon,
    GroupAddIcon,
    PollIcon,
    PauseOutlinedIcon,
    DoubleArrowIcon,
    SimCardIcon,
    SmsIcon,
    ReceiptIcon,
    Settings
}