import BaseService from './BaseService';
import { Http } from '../Http';
import { Response, ResponseList, PriceModel, Options, TadigZoneModel } from '../Redux/Models';

export default class PriceService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<PriceModel>> {
        let url: string = '/prices';
        if (options?.root) {
            url = options.root + url;
        }
        PriceService.initCancelToken();
        if (options?.source) {
            PriceService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: PriceService.source?.token })
                .then(res => {
                    let data = new ResponseList<PriceModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static update(id: string, params: Object = {}): Promise<Response<PriceModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch('/prices/' + id, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<PriceModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }


    public static getById(id: String, params = {}): Promise<Response<PriceModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/prices/' + id, { params, cancelToken: this.source?.token }).then(res => {
                let data = new Response<PriceModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: Object = {}): Promise<Response<PriceModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/prices', params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<PriceModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static delete(id: string, params: Object = {}): Promise<Response<null>> {
        this.cancelToken = Http.CancelToken;
        this.source = this.cancelToken.source();
        return new Promise((resolve, reject) => {
            Http.delete('/prices/' + id, { params, cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static GetTADIGZones(): Promise<Response<Array<TadigZoneModel>>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/zones', { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<Array<TadigZoneModel>>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

}