import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, MenuItem, Checkbox, Button, CircularProgress } from "@material-ui/core";
import { ChangeEvent, RefObject, FormEvent } from "react";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import { InputField, MainBody } from "../../../Components";
import { DataTable } from "../../../Components/DataTable";
import { VALIDATIONS } from "../../../Configs/Constants";
import Mixin from "../../../Mixin";
import { Props, Response, AutomationRuleModel } from "../../../Redux/Models";
import { EditAutomationState } from "../../../Redux/States";
import { AutomationService } from "../../../Services";



export default class EditBilling extends Mixin {
    public state: EditAutomationState = new EditAutomationState();
    public dataTable: RefObject<DataTable<AutomationRuleModel>>;

    public constructor(props: Props) {
        super(props);

        this.state.automationRule.type = "DATA_USAGE";
        this.state.automationRule.event = "LIMIT_MB";
        this.initValidation({
            name: VALIDATIONS.REQUIRED,
            type: VALIDATIONS.REQUIRED,
            action: VALIDATIONS.REQUIRED,
            value: VALIDATIONS.REQUIRED,
        }, {
            name: 'Name',
            type: 'Type',
            action: 'Action',
            value: 'Value'
        });
    }


    componentDidMount() {
        this.getRuleById();
    }

    componentWillUnmount() {
        AutomationService.cancelRequestIfPending();
    }


    public getRuleById = () => {
        const { match: { params }, } = this.props;
        if (params.id) {
            AutomationService.getById(params.id).then(resp => {
                if (resp.isSuccess()) {
                    this.setState((prevState: EditAutomationState) => prevState.automationRule = resp.getData());
                }
            })
        }
    }
    public updateValidation() {
        const { automationRule } = this.state;

        if (automationRule.type == 'DATA_USAGE') {
            this.initValidation({
                name: VALIDATIONS.REQUIRED,
                type: VALIDATIONS.REQUIRED,
                action: VALIDATIONS.REQUIRED,
                event: VALIDATIONS.REQUIRED,
                value: VALIDATIONS.REQUIRED
            }, {
                name: 'Name',
                type: 'Type',
                action: 'Action',
                event: 'Event',
                value: 'Value'
            });

        } else {
            this.initValidation({
                type: VALIDATIONS.REQUIRED,
                action: VALIDATIONS.REQUIRED,
            }, {
                type: 'Type',
                action: 'Action',
            });
        }


    }

    public validateAndUpdate(e: FormEvent) {
        const { match: { params } } = this.props;
        const { errors } = this.validator;
        const { automationRule } = this.state;

        this.validator.validateAll(automationRule).then((success: boolean) => {
            if (success) {
                this.setState((prevState: EditAutomationState) => prevState.loading = true);
                AutomationService.update(params.id, automationRule).then((resp: Response<AutomationRuleModel>) => {
                    this.setState((prevState: EditAutomationState) => prevState.loading = false);
                    // this.props.history.push("/automation");
                }).catch(e => {
                    // this.props.history.push("/automation");
                });
            }
            else {
                this.setState((prevState: EditAutomationState) => prevState.errors = errors);
            };
        });
    }

    public validateAndSubmit = (e: FormEvent) => {
        e.preventDefault();
        const { errors } = this.validator;
        const { automationRule } = this.state;

        this.validator.validateAll(automationRule).then((success: boolean) => {
            if (success) {
                AutomationService.create(automationRule).then((resp: any) => {
                    this.props.history.push("/automation");
                })
            } else {
                this.setState((prevState: EditAutomationState) => prevState.errors = errors);
            }
        });
    }

    public handleChange = (event: ChangeEvent<any>) => {
        const name = event.target.name;
        const value = event.target.value;
        const { errors } = this.validator;
        console.log(errors, name)
        errors.remove(name);
        console.log(errors)

        this.setState((prevState: AutomationRuleModel) => {
            prevState.automationRule[name] = value;
            prevState.errors = errors;
            return prevState;
        });
        this.updateValidation()
    }


    public render() {
        const { match: { params } } = this.props;
        const { errors, automationRule, loading }: EditAutomationState = this.state;
        return (
            <MainBody {...this.props} onBackPress={() => this.props.history.goBack()}>
                <Row className="px-4 py-4">
                    <Col lg="12" md="12" sm="12">
                        <Form onSubmit={this.validateAndSubmit}>
                            <Row>
                                <Col lg="6" md="6" sm="12">
                                    <FormGroup className="mt-3">
                                        <InputField name="name" label="Name" onSave={(e: FormEvent) => this.validateAndUpdate(e)} value={automationRule.name} editable={params.id} className="w-100" onChange={this.handleChange} error={errors.has('name')} helperText={errors.first('name')} />
                                    </FormGroup>
                                </Col>
                                <Col lg="6" md="6" sm="12">
                                    <FormGroup className="mt-3">
                                        <InputField value={automationRule.action} name="action" label="Action" onSave={(e: FormEvent) => this.validateAndUpdate(e)} editable={params.id} onChange={this.handleChange} error={errors.has('action')} className="w-100" helperText={errors.first('action')} select>
                                            <MenuItem key={1} value='DEACTIVE_SIM'>Deactivate SIM</MenuItem>
                                            <MenuItem key={2} value='ACTIVE_SIM'>Activate SIM</MenuItem>
                                            <MenuItem key={3} value='SEND_EMAIL'>Send Email</MenuItem>
                                        </InputField>
                                    </FormGroup>
                                </Col>
                                <Col lg="6" md="6" sm="12">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Type</FormLabel>
                                        <RadioGroup row aria-label="gender" name="type">
                                            <FormControlLabel value='DATA_USAGE' checked={automationRule.type === 'DATA_USAGE'} control={<Radio />} label="Data Usage" onChange={this.handleChange} />
                                            <FormControlLabel value='IMEI_CHANGE' checked={automationRule.type === 'IMEI_CHANGE'} control={<Radio />} label="IMEI Change" onChange={this.handleChange} />
                                        </RadioGroup>
                                    </FormControl>
                                </Col>
                                {automationRule.type === 'DATA_USAGE' && <Col lg="6" md="6" sm="12">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Event</FormLabel>
                                        <RadioGroup row aria-label="gender" name="event">
                                            <FormControlLabel value='LIMIT_MB' checked={automationRule.event === 'LIMIT_MB'} control={<Radio />} label="Limit MB" onChange={this.handleChange} />
                                            <FormControlLabel value='LIMIT_PRICE' checked={automationRule.event === 'LIMIT_PRICE'} control={<Radio />} label="Limit Price" onChange={this.handleChange} />
                                        </RadioGroup>
                                    </FormControl>
                                </Col>}

                                {automationRule.type === 'DATA_USAGE' && automationRule.event && <Col lg="6" md="6" sm="12">
                                    <FormGroup className="mt-3">
                                        <InputField name="value" label="Value" onSave={(e: FormEvent) => this.validateAndUpdate(e)} value={automationRule.value} editable={params.id} className="w-100" onChange={this.handleChange} error={errors.has('value')} helperText={errors.first('value')} />
                                    </FormGroup>
                                </Col>
                                }

                                {automationRule.action && <Col lg="6" md="6" sm="12">
                                    <FormGroup className="mt-3">
                                        <InputField value={automationRule.followUpAction} name="followUpAction" label="Follow Up Action" onSave={(e: FormEvent) => this.validateAndUpdate(e)} editable={params.id} onChange={this.handleChange} error={errors.has('followUpAction')} className="w-100" helperText={errors.first('followUpAction')} select>
                                            <MenuItem key={1} value='DEACTIVE_SIM'>Activate at the start of next month</MenuItem>
                                            <MenuItem key={2} value='ACTIVE_SIM'>Activate at the start of next day</MenuItem>
                                        </InputField>
                                    </FormGroup>
                                </Col>}
                                {!params.id && <Col lg="12" md="12" sm="12">
                                    <div>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={loading ? <CircularProgress size={20} /> : null}
                                            disabled={loading}
                                        >
                                            &nbsp;{params.id ? 'Update' : 'Save'}&nbsp;
                                        </Button>
                                    </div>
                                </Col>
                                }
                            </Row>
                        </Form>
                    </Col>
                </Row >
            </MainBody >
        )
    }
}