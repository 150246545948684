
import { Container } from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { PERMISSIONS, USER_TYPES } from '../Configs/Constants'
import { RouteInterface, routes } from '../Routes/Dashboard';
import { Switch, Route, Link, NavLink, Redirect } from "react-router-dom";
import { Component, Fragment } from 'react';
import { Input, Button, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { LogoWhite } from '../Helpers/Images';
import { SearchIcon, DashboardIcon, NotificationsNoneIcon, ExpandMoreIcon, MenuIcon } from '../Helpers/Icons';
import { Props } from '../Redux/Models';
import { StateModel, AuthUserModel } from '../Redux/Models';
import { DashboardLayoutState } from '../Redux/States';
import { AuthService } from '../Services';
import { ToggleMenu } from '../Redux/Actions';
import { Avatar, IconButton, Paper, List, ListItem, Divider, Chip } from '@material-ui/core';
import { ServerImage } from '../Helpers/String';
import { Menu, MENUS, COUTOMER_MENUS } from '../Configs/Menus';
import DashboardSideMenu from '../Components/DashboardSideMenu';
import { Variables } from '../Configs/Variables';

// Icons 
import FBIcon from '../Components/Icons/FBIcon';
import InstaIcon from '../Components/Icons/InstaIcon';
import LinkedinIcon from '../Components/Icons/LinkedinIcon';
import Twitter2Icon from '../Components/Icons/Twitter2Icon';
import { AppContainer } from './styled';
import { FooterDevider } from '../Components/DashboardSideMenu/style';

class DashboardLayout extends Component<Props, DashboardLayoutState> {

    public state: DashboardLayoutState = new DashboardLayoutState();

    public componentDidMount() {
        this.props.dispatch(AuthService.getMe());
    };

    public logOut(): void {
        this.props.dispatch(AuthService.logout());
    }

    public toggleDropDown(): void {
        this.setState((prevState: DashboardLayoutState) => ({ isOpen: !prevState.isOpen }));
    }

    public toggleMenu = (): void => {
        const { isToggled } = this.props;
        this.props.dispatch(ToggleMenu(!isToggled));
    }

    public toggleMegaMenu(): void {
        this.setState((prevState: DashboardLayoutState) => ({ megaMenu: !prevState.megaMenu }));
    }

    public render() {

        const { history, isToggled, user, toggleTheme } = this.props;
        const { megaMenu } = this.state;

        return (
            <Fragment>
                <DashboardSideMenu {...this.props} />
                <div className="dashboard-main-content">
                    <div className='dashboard-content'>
                        <div style={{
                            flex: 1
                        }}>
                            <Switch>
                                {
                                    routes.map((route: RouteInterface, idx) => {
                                        const title = { title: route.name }
                                        console.log(route)

                                        if (route.module) {
                                            return route.module && route.permissions && user.hasPermission(route.module, ...route.permissions) ? (<Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                render={(props: Props) => <route.component {...this.props} {...props} {...title} />}
                                            />) : null;
                                        } else {
                                            return (<Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                render={(props: Props) => <route.component {...this.props} {...props} {...title} />}
                                            />);
                                        }
                                    })
                                }
                                <Redirect exact to="/dashboard" />
                            </Switch>
                        </div>
                        <div className='mt-3'>
                            <FooterDevider/>
                            <footer className="container-fluid inside-footer d-flex py-3 justify-content-between">
                                <Typography>&#169; Ondigo {new Date().getFullYear()} </Typography>
                                <div className="flex-center">
                                    <FBIcon width={25} height={25} className="px-1" />
                                    <LinkedinIcon width={25} height={25} className="px-1" />
                                    <InstaIcon width={25} height={25} className="px-1" />
                                    <Twitter2Icon width={25} height={25} className="px-1" />
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = (state: StateModel) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        isExpired: state.Auth.isExpired,
        isToggled: state.Config.isToggled,
        roles: state.Config.roles,
        permissions: state.Config.permissions,
        user: new AuthUserModel(state.Auth.user),
    };
};

export default connect(mapStateToProps)(DashboardLayout);
