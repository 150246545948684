
import { Response, AutomationRuleModel, Options, ResponseList } from '../Redux/Models';
import BaseService from './BaseService';
import { Http } from '../Http';

export default class AutomationService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<AutomationRuleModel>> {
        let url: string = 'sims/all-automation';
        if (options?.root) {
            url = options.root + url;
        }
        AutomationService.initCancelToken();
        if (options?.source) {
            AutomationService.source = options?.source
        }

        return new Promise((resolve, reject) => {
            console.log(url);
            Http.get(url, { params, cancelToken: AutomationService.source?.token }).then(res => {
                let data = new ResponseList<AutomationRuleModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: AutomationRuleModel): Promise<ResponseList<AutomationRuleModel>> {
        AutomationService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post("/automation", params)
                .then(res => {
                    let data = new ResponseList<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getById(id: String): Promise<Response<AutomationRuleModel>> {
        AutomationService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get("/automation/" + id)
                .then(res => {
                    let data = new Response<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                })
        })

    }

    public static update(id: string, params: Object = {}, options: Options = {}): Promise<Response<AutomationRuleModel>> {
        this.initCancelToken();
        let url: string = '/automation/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static changeStatus(id: string, params: Object = {}, options: Options = {}): Promise<Response<AutomationRuleModel>> {
        this.initCancelToken();
        let url: string = '/automation/' + id + '/status';
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static delete(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url = '/automation/' + id
        if (options?.root) {
            url = options.root + url
        }
        return new Promise((resolve, reject) => {
            Http.delete(url, { params, cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

}
