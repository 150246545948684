export class Variables {
    public static primary: string = '#00365a';
    public static secondary: string = '#EE8234';
    public static primaryH: string = '#001e31';
    public static black: string = '#040404';
    public static darkGrey: string = '#373737';
    public static grey: string = '#8f8f8f';
    public static greyM: string = '#d7d7d7';
    public static greyL: string = '#f9f9f9';
    public static greyL2: string = '#eeeeee';
    public static white: string = '#ffffff';
    public static authLinkColor: string = '#452778';
    public static authHeadingColor: string = '#452778';
    public static authLeftHeadColor: string = '#F3873C';
    
    public static matPrimaryL: string = '#f3761db2';
    public static matPrimary: string = '#f3873c';
    public static matPrimaryD: string = '#f3761d';
    public static matSecondaryL: string = '#ff7961';
    public static matSecondary: string = '#f44336';
    public static matSecondaryD: string = '#ba000d';
    public static matPurplePrimary: string = '#452778';
}