import { Component, ChangeEvent } from 'react';
import {
    TableContainer,
    Table,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    TablePagination,
    TableSortLabel,
    Checkbox,
    IconButton,
    Switch,
    CircularProgress,
    Tooltip
} from '@material-ui/core';
import { Props, ResponseList, States } from '../../Redux/Models';
import { DataTableHeaderModel, DataTableActionModel } from '../../Redux/Models/Base.Models';
import { GetValue } from '../../Helpers/Object';
import { Http, CancelTokenStatic, CancelTokenSource } from '../../Http';
import { Filter, FindIndex } from '../../Helpers/Array';

export default class DataTable<T extends States> extends Component<Props, States>{
    public state: States = {};
    public cancel: any;
    public cancelToken?: CancelTokenStatic;
    public source?: CancelTokenSource;

    public constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            select: {
                all: false,
                items: []
            },
            actions: props.actions || [],
            status: props.status || {},
            selectble: props.selectble || false,
            items: [],
            total: 0,
            hasItem: false,
            pagination: props.pagination || false,
            params: {
                page: 0,
                limit: props.limit || 10,
                active: props.active || '_id',
                direction: props.direction || 'asc',
                q: '',
            }
        }

    }

    public componentDidMount() {
        this.getData();
    }

    public componentWillUnmount() {
        this.source?.cancel();
    }

    public initCancelToken = () => {
        this.cancelToken = Http.CancelToken;
        this.source = this.cancelToken.source();
    };

    public getData() {
        this.source?.cancel();
        this.setState((prevState: States) => prevState.loading = true);
        const { params }: States = this.state;
        this.initCancelToken();
        // console.log(params);
        this.props.request(params, this.source).then((resp: ResponseList<T>) => {
            this.setState((prevState: States) => prevState.loading = false);
            if (resp.isSuccess()) {
                this.setState((prevState: States) => ({
                    items: resp.getItems(),
                    total: resp.getTotal(),
                    hasItem: resp.data.hasItem,
                }), () => console.log(this.state));
            }
        }).catch(() => this.setState((prevState: States) => prevState.loading = false));
    }

    public handleAllselect = (event: ChangeEvent<HTMLInputElement>) => {
        const { items, select }: States = this.state;
        const filtered = Filter(select.items, (_id: number) => FindIndex(items, (itm: T) => itm._id === _id) === -1);
        if (event.target.checked) {
            items.forEach((element: T) => filtered.push(element._id));
        }
        this.setState((prevState: States) => {
            prevState.select.items = filtered
            return prevState;
        }, () => {
            if (this.props.onSelectItem) {
                this.props.onSelectItem(this.state.select);
            }
        })
    };

    public handleSingleClick = (event: ChangeEvent<HTMLInputElement>, item: T) => {
        const { select }: States = this.state;
        const filtered = Filter(select.items, (_id: string) => item._id !== _id);
        if (event.target.checked) {
            filtered.push(item._id)
        }
        this.setState((prevState: States) => {
            prevState.select.items = filtered
            return prevState;
        }, () => {
            if (this.props.onSelectItem) {
                this.props.onSelectItem(this.state.select);
            }
        })
    };

    public handleChangePage = (event: unknown, newPage: number) => {
        this.setState((prevState: States) => ({
            params: {
                ...prevState.params,
                page: newPage
            },
        }), () => {
            this.getData();
        });
    };

    public handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        const limit = parseInt(event.target.value, 10);
        this.setState((prevState: States) => ({
            params: {
                ...prevState.params,
                page: 0,
                limit: limit
            }
        }), () => {
            this.getData();
        });
    };

    public createSortHandler = (property: String) => {
        const { params }: States = this.state;
        const isAsc = params.active === property && params.direction === 'asc';
        this.setState((prevState: States) => ({
            params: {
                ...prevState.params,
                page: 0,
                active: property,
                direction: isAsc ? 'desc' : 'asc'
            }
        }), () => {
            this.getData();
        });
    };

    public handleAction = (action: DataTableActionModel, data: T) => {
        if (this.props.onActionClick) {
            this.props.onActionClick(action.key, data);
        }
    }

    public handleStatus = (event: ChangeEvent<HTMLInputElement>, data: T) => {
        if (this.props.onStatusChange) {
            this.props.onStatusChange(event, data);
        }
    }

    public selectedItems = () => {
        const { items, select } = this.state
        const selected = Filter(items, (currentItem: T) => FindIndex(select.items, (_id: number) => currentItem._id === _id) > -1).length
        return selected
    }



    public render() {
        const { headers, actionWidth }: Props = this.props;
        const { items, total, params, hasItem, pagination, select, selectble, actions, status, loading }: States = this.state;
        let colSpan = headers.length;
        if (status) colSpan++
        if (actions) colSpan++
        if (selectble) colSpan++

        return (
            <TableContainer>
                <Table
                    className="makeStyles-table-3"
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    <TableHead>
                        <TableRow>
                            {selectble &&
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={this.selectedItems() > 0 && items.length !== this.selectedItems()}
                                        checked={this.selectedItems() > 0 && items.length === this.selectedItems()}
                                        onChange={this.handleAllselect}
                                        inputProps={{ 'aria-label': 'select all desserts' }}
                                    />
                                </TableCell>
                            }
                            {headers.map((header: DataTableHeaderModel, i: any) => (
                                <TableCell
                                    key={i}
                                    align={'left'}
                                    style={header.style}
                                >
                                    {header.isSort ?
                                        <TableSortLabel
                                            active={params.active === header.key}
                                            direction={params.active === header.key ? params.direction : 'asc'}
                                            onClick={(e) => this.createSortHandler(header.key)}
                                        >
                                            <b>{header.label}</b>
                                        </TableSortLabel>
                                        : <b>{header.label}</b>
                                    }
                                </TableCell>
                            ))}
                            {status && status.key &&
                                <TableCell style={{ width: status.width }}>
                                    <b> {status.label} </b>
                                </TableCell>
                            }

                            {actions && !!actions.length &&
                                <TableCell style={{ width: actionWidth }}>
                                    <b>Actions</b>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    {loading ?
                        <TableBody>
                            <TableRow style={{ height: 33 }}>
                                <TableCell colSpan={colSpan} className="text-center"><CircularProgress size={25} thickness={4} /></TableCell>
                            </TableRow>
                        </TableBody> :
                        <TableBody>
                            {items.map((row: T, index: number) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {selectble &&
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={select.items.indexOf(row._id) > -1}
                                                    onChange={(event) => this.handleSingleClick(event, row)}
                                                />
                                            </TableCell>
                                        }

                                        {headers.map((header: DataTableHeaderModel, j: number) => {
                                            return <TableCell key={row._id + j}>
                                                {header?.render ? header.render(row) : j === 0 ? (params.page > 0 ? ((params.page * params.limit) + (index + 1)) : (index + 1)) : GetValue(row, header.key)}
                                            </TableCell>
                                        })}


                                        {status && status.key &&
                                            <TableCell>
                                                <Switch checked={row[status.key]} value={row._id} onChange={(e) => this.handleStatus(e, row)} color="primary" />
                                            </TableCell>
                                        }



                                        {actions && !!actions.length &&
                                            <TableCell>
                                                <div className="d-flex align-items-center action_panel">
                                                    {actions.map((action: DataTableActionModel, i: number) => {
                                                        return action.match ? action.match.value === row[action.match.key] && (
                                                            <Tooltip key={i} title={action.tooltip ? action.tooltip : ''} arrow>
                                                                <IconButton size="medium" onClick={() => this.handleAction(action, row)} color={action.color ? action.color : 'default'} className="btn-sm mr-2">
                                                                    <action.icon fontSize="small" className="m-0" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip key={i} title={action.tooltip ? action.tooltip : ''} arrow>
                                                                <IconButton size="medium" onClick={() => this.handleAction(action, row)} color={action.color ? action.color : 'default'} className="btn-sm mr-2">
                                                                    <action.icon fontSize="small" className="m-0" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        );
                                                    })
                                                    }
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                            {(!hasItem || !total) && (
                                <TableRow style={{ height: 33 }}>
                                    <TableCell colSpan={colSpan} className="text-center">Empty</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    }
                </Table>
                {pagination &&
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[]}
                        count={total}
                        rowsPerPage={params.limit}
                        page={params.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                }
            </TableContainer>
        );
    }
}






