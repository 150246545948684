
import Mixin from '../../../Mixin';
import { Filters, MainBody, InputField } from '../../../Components';
import { BillingListState, CustomerUsageReportState, GeographicDataConsumptionReportState } from '../../../Redux/States';
import { Props, AutomationRuleModel, States, CustomerUsageReportModel, GeographicDataConsumptionReportModel } from '../../../Redux/Models';
import { CircularProgress, IconButton, Tooltip, Modal, Card, Button, CardContent, CardActions } from '@material-ui/core';
import { VisibilityIcon, DeleteIcon } from '../../../Helpers/Icons';
import { AutomationService, BillingService, CompanyService } from '../../../Services';
import { Row, Col } from 'reactstrap';
import DataTable from '../../../Components/DataTable/DataTable';
import { TableHolder } from '../../../Helpers/Styled';
import { createRef, RefObject, FormEvent, ChangeEvent } from 'react';
import { MODULES, PERMISSIONS, USER_TYPES } from '../../../Configs/Constants';
import { BillingModel } from '../../../Redux/Models/Billing.Models';
import { CancelTokenSource } from 'axios';
import GeographicDataConsumptionReportService from '../../../Services/GeographicDataConsumptionReportService';

class GeographicDataConsumptionReport extends Mixin {
    public state: GeographicDataConsumptionReportState = new GeographicDataConsumptionReportState();
    public dataTable: RefObject<DataTable<GeographicDataConsumptionReportModel>>;
    public filters = [];

    constructor(props: Props) {
        super(props);
        this.dataTable = createRef();
        this.state.dataTable.request = this.getGeographicDataConsumptionReport
    }

    public getGeographicDataConsumptionReport = (param: Object, soruce: CancelTokenSource) => {
        return GeographicDataConsumptionReportService.getList(null, param, { root: '', sources: soruce });
    }

    public getGeographicDataConsumptionReportByCompany = (company: string | null, param: Object, soruce: CancelTokenSource) => {
        GeographicDataConsumptionReportService.getList(company, param, { root: '', sources: soruce }).then((item: any) => {
            this.dataTable?.current?.setState({
                items: item.getItems(),
                total: item.getTotal(),
                hasItem: item.data.hasItem,
            })
        })
    }

    componentDidMount(): void {
        if (this.user.type === USER_TYPES.SUPRER_ADMIN) {
            this.getCompanies();
        }
    }

    public getCompanies() {
        CompanyService.getList().then(resp => {
            if (resp.isSuccess()) {
                if (resp.getData().items.length) {
                    this.setState({ companies: resp.getData().items.map((item) => ({ name: item.company.name, key: item._id })) }, () => {
                        console.log("object");
                        this.getGeographicDataConsumptionReportByCompany(this.state.companies[0].key, this.dataTable.current?.state.params, this.dataTable.current?.state.source);
                    });
                }
            }
        });
    }

    public componentWillUnmount() {
        GeographicDataConsumptionReportService.cancelRequestIfPending();
    }

    public onSearch = (param: any) => {
        const { filter, q } = param;
        this.dataTable.current?.setState((prevState: States) => ({
            params: {
                ...prevState.params,
                q: q
            }
        }), () => {
            this.getGeographicDataConsumptionReportByCompany(!filter?.key ? null : filter.key, this.dataTable.current?.state.params, this.dataTable.current?.state.source);
        });
    }



    public render() {
        const { match: { params } } = this.props;
        const { companies } = this.state;
        // const addUrl = `${params.reseller ? `/resellers/${params.reseller}` : ``}/automation/new`
        return (
            <MainBody {...this.props} onBackPress={() => this.props.history.goBack()} onAddPress={false} >
                <Row className="pt-3">
                    <Col>
                        <div className="d-flex align-items-center action_panel">
                            <Tooltip title='' arrow>
                                <IconButton size="medium" className="btn-sm mr-2">
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Col>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h3 className="mb-4">Geographic Data Consumption Report</h3>
                        {this.user.type === USER_TYPES.SUPRER_ADMIN ?
                            <Filters onSearch={this.onSearch} filters={companies} selectOne={true} /> :
                            <Filters onSearch={this.onSearch} filters={companies} selectOne={true} />}
                    </div>
                </Row>
                <Row>
                    <Col lg="12" className="mt-4">
                        <TableHolder className="table-holder">
                            <DataTable
                                ref={this.dataTable}
                                {...this.state.dataTable}
                            />
                        </TableHolder>
                    </Col>
                </Row>
            </MainBody>
        );
    }

}

export default GeographicDataConsumptionReport