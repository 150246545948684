import { UserService } from "../../Services";
import { UserModel } from "../Models/User.Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BaseStateModel, DataTableModel, BranchModel } from "../Models";

export class UserListState {
    public users: Array<UserModel>;
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '20%',
        actions: [
            { key: 'view', icon: VisibilityIcon },
        ],
        request:UserService.getList,
        headers:[
            { key:'i', label: '#', isSort: false, style:{ width:'10%' }},
            { key:'type', label: 'Type', isSort: false, style:{ width:'10%' }},
            { key:'firstName', label: 'First Name', isSort: true, style:{ width:'10%' } },
            { key:'lastName', label: 'Last Name', isSort: true, style:{ width:'10%' } },
            { key:'email', label: 'Email', isSort: true, style:{ width:'25%' } },
            { key:'phoneNumber', label: 'Phone Number', isSort: true, style:{ width:'15%' } },
        ]
    }
}

export class UserDetailState extends BaseStateModel {
    public user: UserModel = { 
        firstName: '',
        lastName: '', 
        email: '', 
        phoneNumber: '', 
        currentPassword: '',
        newPassword: '',
        confirmPassword: '' 
    }
}


export class EditUserState extends BaseStateModel {
    public loading: boolean = false;
    public branches: Array<BranchModel> = [];
    public userModel: UserModel = {
        type: '',
        branch: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    }
}