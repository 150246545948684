
import { BaseStateModel, DataTableModel, BranchModel, UserModel, CustomerUsageReportModel, DataUsageTrendReportModel, DeviceUsageReportModel, HighUsageCustomerReportModel, ProviderPerformanceReportModel, ProviderCostAnalysisReportModel, CompanyModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BillingService } from "../../Services";

export class ProviderCostAnalysisReportState {
    public dataUsageReports: Array<ProviderCostAnalysisReportModel> = [];
    public companies: Array<CompanyModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        //  <COSTPRICEPERMB_PROVIDER> and <PROVIDER>
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'provider', label: 'PROVIDER', isSort: true },
            { key: 'costPrice', label: 'COST PRICE PER MB PROVIDER', isSort: true },
        ]
    }
}
