import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'

export class AddIcon extends Component<Props, States> {
    static defaultProps = {
        width: 24,
        height: 24
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.colors.textColor;

        return (
            <SVGContainer {...restProps}>
                <svg width={width} height={height} viewBox="0 0 33 32" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1157 0C13.1702 0 10.7824 2.38782 10.7824 5.33333V10.6667H5.44906C2.50354 10.6667 0.115723 13.0545 0.115723 16C0.115723 18.9455 2.50354 21.3333 5.44906 21.3333H10.7824V26.6667C10.7824 29.6122 13.1702 32 16.1157 32C19.0612 32 21.4491 29.6122 21.4491 26.6667V21.3333H26.7824C29.7279 21.3333 32.1157 18.9455 32.1157 16C32.1157 13.0545 29.7279 10.6667 26.7824 10.6667L21.4491 10.6667V5.33333C21.4491 2.38781 19.0612 0 16.1157 0Z" fill="currentColor" />
                </svg>
            </SVGContainer>
        );
    }
}

export default withTheme(AddIcon);



