import { PriceService } from "../../Services";
import { BaseStateModel, DataTableModel, PriceModel, ZoneModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';


export class PriceListState extends BaseStateModel {
    public prices: Array<PriceModel> = [];
    public loading: boolean = false;
    public noData: boolean = false;
    public selectedId: string | number | null = null;
    public isCreateMode: boolean = false;
    public dataTable: DataTableModel = {
        pagination: false,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '20%',
        actions: [
            { key: 'view', icon: VisibilityIcon },
        ],
        request: PriceService.getList,
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '40%' } },
            { key: 'name', label: 'Name', isSort: true, style: { width: '50%' } }
        ]
    }
}

export class EditPriceState extends BaseStateModel {
    public expanded: Number = 1;
    public loading: boolean = false;
    public price: PriceModel = {
        name: '',
        baseMargin: '',
        baseMonthlyCost: '',
        baseSMSCost: '',
        plusMargin: '',
        plusMonthlyCost: '',
        plusSMSCost: '',
        zones: [{
            name: '',
            cost: '',
            type: 'BASE',
            plmn: '',
            country: ''
        }, {
            name: '',
            cost: '',
            type: 'PLUS',
            plmn: '',
            country: ''
        }]
    };
}

export class ZonePriceState extends BaseStateModel {
    public expanded: Number = 1;
    public loading: boolean = false;
    public zones: any = [{
        name: '',
        cost: '',
        type: 'BASE',
        plmn: '',
        country: ''
    }, {
        name: '',
        cost: '',
        type: 'PLUS',
        plmn: '',
        country: ''
    }]
}


export class NewPriceState extends BaseStateModel {
    public zones: Array<ZoneModel> = [];
    public noZones?: Boolean = false;
}

export class PriceViewState extends BaseStateModel {
    public loading: boolean = false;
    public price: PriceModel = {
        name: '',
        zones: []
    };
} 
