
import { BaseStateModel, DataTableModel, BranchModel, UserModel, CustomerUsageReportModel, DataUsageTrendReportModel, DeviceUsageReportModel, CustomerProviderPreferenceReportModel, CostAnalysisReportModel, CompanyModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BillingService } from "../../Services";

export class CostAnalysisReportState {
    public dataUsageReports: Array<CostAnalysisReportModel> = [];
    public companies: Array<CompanyModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        //  <COSTPRICEPERMB_PROVIDER>, <PRICEPERMB_PROVIDER>, <PROVIDER>, and <DATAUSED>
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'COSTPRICEPERMB_PROVIDER', label: 'COSTPRICEPERMB_PROVIDER', isSort: true },
            { key: 'PRICEPERMB_PROVIDER', label: 'PRICEPERMB_PROVIDER', isSort: true },
            { key: 'PROVIDER', label: 'PROVIDER', isSort: true },
            { key: 'DATAUSED', label: 'DATAUSED', isSort: true }
        ]
    }
}
