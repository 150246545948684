
import { BaseStateModel, DataTableModel, BranchModel, UserModel, CustomerUsageReportModel, DataUsageTrendReportModel, DeviceUsageReportModel, CustomerProviderPreferenceReportModel, CompanyModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BillingService } from "../../Services";

export class CustomerProviderPreferenceReportState {
    public dataUsageReports: Array<CustomerProviderPreferenceReportModel> = [];
    public companies: Array<CompanyModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        //  <CUSTOMERID>, <CUSTOMERNAME>, <PROVIDER>, <DATAUSED>
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'customerId', label: 'CUSTOMER ID', isSort: true },
            { key: 'customerName', label: 'CUSTOMERNAME', isSort: true },
            { key: 'provider', label: 'PROVIDER', isSort: true },
            { key: 'usage', label: 'DATAUSED', isSort: true }
        ]
    }
}
