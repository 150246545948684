import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Variables } from '../Configs/Variables';
import { CardContent, Button, styled as MatStyle  } from '@material-ui/core';

export const ZoneItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    padding:8px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: left;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    &:hover {
        .zone-actions {
          display: block;
        }
    }
    .zone-actions {
        margin-top: -32px;
    }
`;


export const IconButton = MatStyle(Button)(({theme})=>{
    return {
        minWidth: 25,
        padding: 0,
        height: 25,
    }
});

export const InputHolder = styled.div`
    position: relative;
    &:hover {
        .input-actions {
          display: block;
        }
    }
`;

export const InputActions = styled.div`
   position: absolute;
   right: 3%;
   top: 25%;
   z-index: 1;
   display: none;
`;

export const CreateGrid = (templateCol: string, gridGap: string) => {
    return `display: grid;
        grid-template-columns: ${templateCol};
        grid-gap: ${gridGap};`;
}

export const Activity = styled(CardContent)`
.step{
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-left: 18px;
    &:not(:last-child){
        padding-bottom: 20px;
    }
    margin-left: 8px;

    &:last-child{
      .border{
        display: none;
      }
    }

    .MuiSvgIcon-root{
        font-size: 14px;
    }

    .border, .circular-icon{
        position: absolute;
        top: 4px;
    }

    .border{
        height: 100%;
        left: -2.25px;
        border: 2px dashed ${Variables.greyL2} !important;
    }

    .number-part{
        min-width: 85px;
        justify-content: flex-start;
    }

    .circular-icon{
        width: 16px;
        height: 16px;
        border-radius: 100%;
        left: -8px;
        background-color: ${Variables.white};
        border: 2px solid ${Variables.primary};
        .MuiSvgIcon-root{
            font-size: 10px;
        }
    }
}
`;

export const NumberRepresentators = styled(CardContent)`
    ${CreateGrid('1fr 60px', '15px')};
    padding: 15px;
    align-items: center;
`;


export const AddUserlistButton = styled(Link)`
    i::before{
        font-size: 20px;
    }
`;

export const TableHolder = styled.div`
    overflow: auto;
    th, td{
        white-space: nowrap;
    }
`;

export const DashboardGrid = styled.div`
    @media(min-width: 1024px){
        ${CreateGrid('1fr 2fr', '15px')}
    }
`;

export const CardProfileWithBG = styled.div`
    .header-part{
        background-color: ${Variables.matPrimary};
        padding: 15px 15px 0;
        color: ${Variables.white};
        position: relative;

        .profile-img{
            position: absolute;
            left: 20px;
            bottom: -35px;
            width: 70px;
            height: 70px;
            border-radius: 100%;
            overflow: hidden;
            box-shadow: 0 0 0 5px ${Variables.white};
        }
    }

    .details-grid{
        ${CreateGrid('1fr 2fr', '15px')}
    };
  
`;

export const CircularIconHolder = styled.div`
    width: 60px;
    height: 60px;
    background-color: ${Variables.primary};
    border-radius: 100%;
    color: ${Variables.white};
    display: flex;
    justify-content: center;
    align-items: center;

    &.small{
        width: 40px;
        height: 40px;
    }
`;

export const DashboardActivitySellingGrid = styled.div`
    @media(min-width: 768px){
        ${CreateGrid('repeat(2, 1fr)', '15px')};
    }
`;

export const MonthlyEarningGrid = styled.div`${CreateGrid('2fr 1fr', '15px')}`;


export const NumberRepresentatorsGrid = styled.div`${CreateGrid('repeat(3, 1fr)', '15px')}`;

export const DetailsPart = styled.div`
    .grid:not(:first-child){
        margin-top: 15px;
    }

    @media(min-width: 992px){
        .grid{
            display: grid;
            grid-template-columns: 200px 1fr;
            grid-gap: 15px;

            .grid-child:first-child, .bubble{
                padding: 8px 0;
            }

            .bubble-holder{
                padding-left:16px;
                position: relative;

                .bubble{
                    height: 100%;
                    background-color: ${Variables.greyL2};
                    border-radius: 5px;
                    padding-left: 15px;
                }

                &::after{
                    content: '';
                    position: absolute;
                    left: 8px;
                    top: 12px;
                    width:16px;
                    height:16px;
                    transform: rotate(45deg);
                    background-color: ${Variables.greyL2};
                }
            }
        }
    }
    
    @media(max-width: 991px){
        .grid{
            .grid-child:first-child{
                margin-bottom: 5px;
            }
        }
    }
`;

export const AuthButtonPart = styled.div`
    .MuiButton-containedPrimary{
        padding-top:10px;
        padding-bottom:10px;
    }
    .link{
        color:${Variables.authLinkColor};
        display: inline-block;
        font-size: 15px;
        text-decoration:underline !important;
    }
`;

export const AuthHeadPart = styled.div`
    .heading{
        font-size: 30px;
        font-weight: bold;
        letter-spacing: normal;
        color: ${Variables.authHeadingColor};
    }
`;

export const AuthLeftHead = styled.div`
    position: absolute;
    bottom: 15px;
    width: 300px;
    left: 30px;
    .auth_left_head{
        h2{
            font-size: 36px;
            color: #f9faf8;
            font-weight: bold;
            line-height: 1.08;
            position:relative;
            &:before {
                content: '';
                display: block;
                overflow: hidden;
                clear: both;
                background: ${Variables.white};
                width: 50px;
                position: absolute;
                height: 4px;
                top: -20px;
            }
            .highlight_text{
                color:${Variables.authLeftHeadColor};
                display: block;
            }
        }
    }
`;

export const ProfileHeader = styled.div`
    padding-bottom: 30px;
    // border-bottom: 1px solid ${Variables.greyM};
    ${CreateGrid('120px 1fr 1fr', '15px')};
    .img-part{
        border-radius: 8px;
        overflow: hidden;
        min-height: 100px;
        .image_pane{
            width: 100%;
            height: 120px;
            background-size: 100% 100%;
        }
        .MuiIconButton-root{
            color:#fff;
        }
    }
    
    .btn-holder{
       justify-self: end;
    }

    @media(max-width: 1023px){
        .img-part{
            grid-row:1/3;
        }

        .btn-holder{
            grid-column: 2/4;
            grid-row: 1/2;
        }
    }

    @media(min-width: 500px) and (max-width: 1023px){
        .text-part{
            grid-column: 2/4;
            grid-row: 2/3;
            text-align: right;
        }
    }

    @media(max-width: 499px){
        grid-template-columns: 70px 1fr;
        .change-profile-pic{
            display: none;
        }

      .text-part{
        text-align: right;
        .name{
            font-size:18px;
        }
      }
    }

    @media(max-width: 360px){
      .text-part{
        .name{
            font-size:12px;
        }
      }
    }

    @media(min-width: 500px){
        .img-part{
            
        }
    }

    @media(min-width: 1024px){
        .text-part{
           display: flex;
           flex-direction: column;
           justify-content: center;
        } 
    }
`;