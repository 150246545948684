import Mixin from '../../Mixin';
import { StyledMenu } from './style';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import LogoIcon from '../Icons/LogoIcon';
import SimIcon from '../Icons/SimIcon';
import SunnyIcon from '../Icons/SunnyIcon';
import WalletIcon from '../Icons/WalletIcon';
import UserIcon from '../Icons/UserIcon';
import ShopIcon from '../Icons/ShopIcon';
import BellIcon from '../Icons/BellIcon';
import LogoutIcon from '../Icons/LogoutIcon';
import SettingsIcon from '../Icons/SettingsIcon';
import { Menu, MENUS, COUTOMER_MENUS } from '../../Configs/Menus';
import { Props } from '../../Redux/Models';
import { Component } from 'react';
import { MODULES, PERMISSIONS, USER_TYPES } from '../../Configs/Constants';
import { DashboardSideMenuState } from '../../Redux/States';
import { AuthService } from '../../Services';
import { withTheme } from 'styled-components'

class DashboardSideMenu extends Component<Props> {

    public state: DashboardSideMenuState = new DashboardSideMenuState();

    constructor(props: Props) {
        super(props);
        this.state.sideMenuStatus = {
            [MODULES.REPORTS]: false
        };

    }

    public selectMenu = (menu: string, status: boolean) => {
        this.setState({
            sideMenuStatus: {
                [menu]: status
            }
        });
    }

    public logOut(): void {
        this.props.dispatch(AuthService.logout());
    }

    public render() {
        const { history, isToggled, user, toggleTheme, theme } = this.props;
        const { sideMenuStatus } = this.state;

        return (
            <StyledMenu mode={theme.type}>
                <div className='flex-center'>
                    <LogoIcon />
                </div>
                <ul className='menu-part'>
                    <li>
                        <Button component={NavLink} to="/dashboard" activeClassName="activeLink" fullWidth><SimIcon /> <span className='ml-2'>Dashboard</span></Button>
                    </li>
                    {
                        MENUS.map((menu: Menu, index: number) => {
                            for (const item of menu.modules) {
                                return user.hasPermission(item, PERMISSIONS.READ, PERMISSIONS.READ_WRITE) ? (
                                    <li>
                                        <Button component={NavLink} key={menu.title} onClick={() => this.selectMenu(item, !sideMenuStatus?.[item])} to={menu.path} activeClassName={menu.activeClass} fullWidth>{menu.icon} <span className='ml-2'>{menu.title}</span></Button>
                                        {sideMenuStatus?.[item] ?
                                            menu?.submenus && menu?.submenus.length ? <ol className='submenu'>
                                                {menu.submenus.map((item) => {
                                                    for (const module of item.modules) {
                                                        return user.hasPermission(module, PERMISSIONS.READ, PERMISSIONS.READ_WRITE) ? (<li>
                                                            <Button component={NavLink} key={item.title} to={item.path} activeClassName={item.activeClass} fullWidth>{item.icon} <span className='ml-2'>{item.title}</span></Button>
                                                        </li>) : null;
                                                    }
                                                })}
                                            </ol> : <></> : null}
                                    </li>
                                ) : null;
                            }
                        })
                    }

                    {
                        user.hasType(USER_TYPES.CUSTOMER) && COUTOMER_MENUS.map((menu: Menu) => {
                            for (const item of menu.modules) {
                                return user.hasPermission(item, PERMISSIONS.READ, PERMISSIONS.READ_WRITE) ? (
                                    <li>
                                        <Button component={NavLink} key={menu.title} to={menu.path} activeClassName={menu.activeClass} fullWidth>{menu.icon} <span className='ml-2'>{menu.title}</span></Button>
                                        {menu?.submenus && menu?.submenus.length ? <ul>
                                            {menu.submenus.map((item) => (<li>
                                                <Button component={NavLink} key={item.title} to={item.path} activeClassName={item.activeClass} fullWidth>{item.icon} <span className='ml-2'>{item.title}</span></Button>
                                            </li>))}
                                        </ul> : <></>}
                                    </li>
                                ) : null;
                            };
                        })
                    }
                    <li>
                        <Button fullWidth><BellIcon /> <span className='ml-2'>Notifications</span></Button>
                    </li>
                    <li>
                        <Button component={NavLink} to="/profile" activeClassName="activeLink" fullWidth><SettingsIcon /> <span className='ml-2'>Account</span></Button>
                    </li>
                    <li>
                        <Button className="mode" fullWidth onClick={toggleTheme}><SunnyIcon /> <span className='ml-2'>Mode</span></Button>
                    </li>
                    <li>
                        <Button fullWidth onClick={() => this.logOut()}><LogoutIcon /><span className='ml-2'>Logout</span></Button>
                    </li>
                </ul>
            </StyledMenu >
        )
    }
}

export default withTheme(DashboardSideMenu);