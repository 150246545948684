import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'

export class BellIcon extends Component<Props, States> {
    static defaultProps = {
        width: 24,
        height: 24
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.colors.textColor;

        return (
            <SVGContainer {...restProps}>
                <svg width={width} height={height} fill={color} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 25H17.5C17.5 26.3807 16.3807 27.5 15 27.5C13.6193 27.5 12.5 26.3807 12.5 25Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.5C14.3096 2.5 13.75 3.05964 13.75 3.75V5C13.75 5.03468 13.7514 5.06904 13.7542 5.10301C10.205 5.6964 7.50005 8.78231 7.50005 12.5V14.9467C7.50005 15.4851 7.30516 16.0053 6.9514 16.4111L6.14585 17.3353C3.53732 20.3278 5.66274 25 9.63261 25H20.3675C24.3374 25 26.4628 20.3278 23.8543 17.3353L23.0487 16.4111C22.6949 16.0053 22.5001 15.4851 22.5001 14.9467V12.5C22.5001 8.78227 19.795 5.69634 16.2458 5.10299C16.2486 5.06903 16.25 5.03468 16.25 5V3.75C16.25 3.05964 15.6904 2.5 15 2.5ZM10.0001 12.5C10.0001 9.73858 12.2386 7.5 15.0001 7.5C17.7615 7.5 20.0001 9.73858 20.0001 12.5V14.9467C20.0001 16.089 20.4136 17.1927 21.1642 18.0538L21.9697 18.978C23.1683 20.3531 22.1917 22.5 20.3675 22.5H9.63261C7.8084 22.5 6.83175 20.3531 8.0304 18.978L8.83595 18.0538C9.58654 17.1927 10.0001 16.089 10.0001 14.9467V12.5Z" fill="currentColor" />
                </svg>

            </SVGContainer>
        );
    }
}

export default withTheme(BellIcon);



