import BaseService from './BaseService';
import { Http } from '../Http';
import { Response, PriceModel } from '../Redux/Models';

export default class PriceService extends BaseService {

    public static getById(id: String, params = {}): Promise<Response<PriceModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/userPlan/'+ id, { params, cancelToken:this.source?.token }).then(res => {
                let data = new Response<PriceModel>(res?.data);
                return resolve(data);
            })
            .catch(err => {
                return reject(err);
            });
        });
    }

    public static update(id:string, params: Object = {}): Promise<Response<PriceModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch('/userPlan/' + id, params, { cancelToken:this.source?.token })
                .then(res => {
                    let data = new Response<PriceModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
    
}