
import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ResponseList, SimModel, UserModel } from '../Redux/Models';

export default class UserService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<UserModel>> {
        let url: string = '/users';
        if (options?.root) {
            url = options.root + url;
        }
        UserService.initCancelToken();
        if (options?.source) {
            UserService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: UserService.source?.token })
                .then(res => {
                    let data = new ResponseList<UserModel>(res.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: Object = {}, options: Options = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        let url = '/users'
        if (options?.root) {
            url = options?.root + url
        }
        return new Promise((resolve, reject) => {
            Http.post(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<UserModel>(res.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static update(id: string, params: Object = {}, options: Options = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        let url = '/users/' + id
        if (options?.root) {
            url = options.root + url
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<UserModel>(res.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static delete(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url = '/users/' + id
        if (options?.root) {
            url = options.root + url
        }
        return new Promise((resolve, reject) => {
            Http.delete(url, { params, cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateStatus(id: string, params: Object = {}, options: Options = {}): Promise<Response<UserModel>> {
        let url = '/users/' + id + '/status'
        if (options.root) {
            url = options.root + url
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<UserModel>(res.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static addSims(id: String, params = {}, options: Options = {}): Promise<Response<SimModel[]>> {
        let url: string = '/users/' + id + '/sims';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post(url, params).then(res => {
                let data = new Response<SimModel[]>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }


    public static getById(id: String, params = {}, options: Options = {}): Promise<Response<UserModel>> {
        let url: string = '/users/' + id
        if (options?.root) {
            url = options.root + url;
        }
        // this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: this.source?.token }).then(res => {
                let data = new Response<UserModel>(res.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}