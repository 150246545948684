
import { BaseStateModel, AutomationRuleModel, DataTableModel } from "../Models";
import { AutomationService } from "../../Services";
import { VisibilityIcon } from '../../Helpers/Icons';


export class ListAutomationRuleState extends BaseStateModel { 
    public rules: Array<AutomationRuleModel> = [];
    public selectedId: string | number | null = null;
    public loading: boolean = false;
    public noData: boolean = false;
    public isCreateMode: boolean = false;
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }
    public dataTable: DataTableModel = {
        pagination: false,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '20%',
        request: AutomationService.getList,
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '40%' } },
            { key: 'name', label: 'Name', isSort: true, style: { width: '50%' } }
        ]
    }
}


export class EditAutomationState extends BaseStateModel {
    public loading: boolean = false;
    public email: string = "";
    public automationRule: AutomationRuleModel = {
        name: '',
        type: '',
        event: '',
        value: '',
        action: '',
        followUpAction: '',
        sendEmail:false,
        emailIds:[],
        status: true,
        group: [],
        tags: [],
        user: []
    };
}