import { PaymentService } from "../../Services";
import { DataTableModel, DataUsageModel } from "../Models";

export class CreditUsageListState {
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '10%',
        request: PaymentService.getCreditUsage,
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '5%' } },
            { key: 'MSISDN', label: 'MSISDN', isSort: false, style: { width: '10%' } },
            { key: 'thingName', label: 'Name', isSort: false, style: { width: '10%' } },
            {
                key: 'sim', label: 'Price Plan', isSort: false, style: { width: '10%' }, render: (cdr: DataUsageModel) => {
                    return cdr.sim.plan.plan.name + ` (${cdr.sim.plan.type})`;
                },
            },
            {
                key: 'usage', label: 'Data Usage', isSort: false, style: { width: '10%' }, render: (cdr: DataUsageModel) => {
                    return cdr.usage + " Bytes";
                }
            },

            { key: 'cost', label: 'Cost', isSort: false, style: { width: '10%' } },

        ]
    }
}