import { ConfigActionTypes, ConfigActions } from "../Actions";
import { InitialConfigStateModel } from "../Models/Base.Models";
import { Filter, FindIndex } from '../../Helpers/Array'

const initialState:InitialConfigStateModel = {
    isToggled:true,
    roles:[],
    permissions:[],
}

const Config = (state = initialState, action : ConfigActions) : InitialConfigStateModel  => {
    const { type, payload } = action;
    switch(type){
        case ConfigActionTypes.ToggleMenu:{
            return {
                ...state,
                isToggled:payload,
            }
        }
        case ConfigActionTypes.LoadRoles:{
            return {
                ...state,
                roles:payload,
            }
        }
        case ConfigActionTypes.AddRole:{
            const roles = state.roles;
            roles.push(payload);
            return {
                ...state,
                roles:roles,
            }
        }
        case ConfigActionTypes.UpdateRole:{
            const index = FindIndex(state.roles , (r) => r._id === payload._id);
            state.roles[index] = payload;
            return state;
        }
        case ConfigActionTypes.DeleteRole:{
            const roles = Filter(state.roles, (role)=>role._id !== payload)
            return {
                ...state,
                roles:roles,
            }  
        }
        case ConfigActionTypes.LoadPermissions:{
            return {
                ...state,
                permissions:payload,
            }
        }
        default:{
            return state;
        }
    }
}

export default Config;