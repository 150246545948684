
import { BaseStateModel, DataTableModel, BranchModel, UserModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BillingService } from "../../Services";
import { BillingModel } from "../Models/Billing.Models";

export class BillingListState {
    public credits: Array<BillingModel> = [];
    public noData: boolean = false;
    public bills: Array<UserModel>;
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        // MSISDN, IMSI, NAME, DATA USAGE, COST
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'MSISDN', label: 'MSISDN', isSort: true },
            { key: 'IMSI', label: 'IMSI', isSort: true },
            { key: 'thingName', label: 'NAME', isSort: true },
            { key: 'usage', label: 'DATA USAGE', isSort: true },
            { key: 'cost', label: 'COST', isSort: true }
        ]
    }
}
