import { ModulePermissionModel } from "./Role.Models";
import { GetValue } from '../../Helpers/Object'
import { CUSTOMER_MODULES, RESELLER_MODULES, SUPERADMIN_MODULES, USER_TYPES, USER_MODULES } from '../../Configs/Constants';
import { BaseModel } from "./Base.Models";

export class UserModel extends BaseModel {
    public constructor(params = {}) {
        super();
        Object.assign(this, params);
    }
    public firstName?: string;
    public lastName?: string;
    public phoneNumber?: string;
    public email?: string;
    public owner?: string;
    public addedBy?: string;
    public password?: string;
    public isBlocked?: boolean;
    public MFAuth?: Boolean;
}

export class AuthUserModel extends UserModel {

    public USER_TYPES = USER_TYPES;

    public constructor(params = {}) {
        super(params)
        Object.assign(this, params);
    }

    public permissions?: Array<ModulePermissionModel>


    public myUserTypes(): Array<string> {
        switch (this.type) {
            case USER_TYPES.SUPRER_ADMIN: {
                return [
                    USER_TYPES.CUSTOMER,
                    // USER_TYPES.RESELLER,
                ]
            }

            case USER_TYPES.RESELLER: {
                return [
                    USER_TYPES.CUSTOMER
                ]
            }

            case USER_TYPES.CUSTOMER: {
                return [

                ]
            }

            default: {
                return [];
            }
        }
    }

    public hasType(type: string): boolean {
        return this.type === type;
    }

    public hasAnyType(...types: Array<string>): boolean {
        return types.indexOf(this.type) !== -1;
    }

    public hasRole(role: string): boolean {
        return this.role?.name === role;
    }

    public hasAnyRole(...roles: Array<string>): boolean {
        return roles.indexOf(this.role.name) !== -1;
    }

    public hasPermission(module: string = '', ...keys: Array<string>): boolean {
    //    console.log(module, this.type)
        switch (this.type) {
            case USER_TYPES.SUPRER_ADMIN: {
                console.log(SUPERADMIN_MODULES.indexOf(module) !== -1)
                return SUPERADMIN_MODULES.indexOf(module) !== -1;
            }

            case USER_TYPES.RESELLER: {
                return RESELLER_MODULES.indexOf(module) !== -1;
            }

            case USER_TYPES.CUSTOMER: {
                return CUSTOMER_MODULES.indexOf(module) !== -1;
            }
            case USER_TYPES.USER: {
                return USER_MODULES.indexOf(module) !== -1
            }
            default: {

                if (this.permissions && this.permissions.length) {

                    for (const permission of this.permissions) {
                        // console.log(GetValue(permission, 'module.key'));

                        if (GetValue(permission, 'module.key') === module) {
                            // console.log(permission.permissions);
                            for (const p of permission.permissions) {
                                if (keys.indexOf(GetValue(p as any, 'key')) !== -1) {
                                    return true;
                                }
                            }
                        }
                    }
                }
                return false;
            }
        }
    }
}

export class LoadUserModel {
    public token: string;
    public user: UserModel
}