
import { BaseStateModel, DataTableModel, BranchModel, UserModel, CustomerUsageReportModel, DataUsageTrendReportModel, DeviceUsageReportModel, HighUsageCustomerReportModel, CompanyModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BillingService } from "../../Services";

export class HighUsageCustomerReportState {
    public dataUsageReports: Array<HighUsageCustomerReportModel> = [];
    public companies: Array<CompanyModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        //  <CUSTOMERID>, <CUSTOMERNAME>, and <DATAUSED>
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'customerId', label: 'CUSTOMER ID', isSort: true },
            { key: 'customerName', label: 'CUSTOMER NAME', isSort: true },
            { key: 'totalUsage', label: 'DATA USED', isSort: true },
        ]
    }
}
