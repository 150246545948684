import { ResellerService } from "../../Services";
import { PriceModel, BaseStateModel, CompanyModel, DataTableModel, RoleModel, UserModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';


export class ResellerListState {
    public users: Array<UserModel> = [];
    public prices: Array<PriceModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '20%',
        actions: [
            { key: 'view', icon: VisibilityIcon },
        ],
        request: ResellerService.getList,
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'company.name', label: 'Name', isSort: true },
            { key: 'company.invoicingEmail', label: 'Email', isSort: true },
            { key: 'company.invoicingPhone', label: 'Phone Number', isSort: true },
        ]
    }
}

export class ResellerDetailState extends BaseStateModel {
    public user: UserModel = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }
}

export class EditResellerState extends BaseStateModel {
    public roles: Array<RoleModel> = [];
    public prices: Array<PriceModel> = [];
    public loading: boolean = false;
    public companyModel: CompanyModel = {
        name: '',
        administrativeFirstName: '',
        administrativeLastName: '',
        administrativePhoneNumber: '',
        administrativeEmail: '',
        invoicingName: '',
        invoicingPhone: '',
        invoicingEmail: '',
        invoicingAddress: '',
        hqAddress: '',
        hqDeliveryAddress: '',
        hqPostalCode: '',
        hqCity: '',
        hqCountry: '',
        VAT: '',
        // plan:'',
        // cost: 0
    };
}
