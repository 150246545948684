import { UserModel, LoadUserModel } from "../Models"

export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    CheckAuth = '[CheckAuth] Action',
    LoadUser = '[Update Profile] Action',
}

export const Login = (payload:UserModel) => {
    return {
        type:AuthActionTypes.Login,
        payload
    }
}

export const Logout = () => {
    return {
        type:AuthActionTypes.Logout,
        payload:  UserModel
    }
}

export const LoadUser = (payload:UserModel) => {
    return {
        type:AuthActionTypes.LoadUser,
        payload
    }
}

export const CheckAuth = () => {
    return {
        type:AuthActionTypes.CheckAuth
    }
}

export class AuthActions {
    type:AuthActionTypes
    payload:  UserModel | LoadUserModel 
};

