
import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ResponseList, paymentModel, DataUsageModel } from '../Redux/Models';
import FileDownload from 'js-file-download';
import axios from 'axios';

export default class PaymentService extends BaseService {

    public static initRecharge(params: object = {}): Promise<Response<any>> {
        PaymentService.initCancelToken();

        return new Promise((resolve, reject) => {
            Http.post('/initrecharge', params).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                reject(err)
            })
        })

    }

    public static initPayPalRecharge(params: object = {}): Promise<Response<any>> {
        PaymentService.initCancelToken();

        return new Promise((resolve, reject) => {
            Http.post('/init-paypal-recharge', params).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                reject(err)
            })
        })

    }

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<paymentModel>> {
        let url: string = '/payments';
        if (options?.root) {
            url = options.root + url;
        }
        PaymentService.initCancelToken();
        if (options?.source) {
            PaymentService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: PaymentService.source?.token })
                .then(res => {
                    let data = new ResponseList<paymentModel>(res.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getListByCompany(id: string, params: Object = {}, options: Options = {}): Promise<ResponseList<paymentModel>> {
        PaymentService.initCancelToken();

        return new Promise((resolve, reject) => {
            Http.get('/payments/' + id + '/company', { params, cancelToken: PaymentService.source?.token }).then(resp => {
                let data = new ResponseList<paymentModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                reject(err)
            })
        })

    }

    public static downloadPDF(id: string) {
        axios({
            url: `download-invoice/${id}`,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'report.pdf');
        });
    }


    public static getById(id: String, params = {}, options: Options = {}): Promise<Response<paymentModel>> {
        let url: string = '/users/' + id
        if (options?.root) {
            url = options.root + url;
        }
        // this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: this.source?.token }).then(res => {
                let data = new Response<paymentModel>(res.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getCreditUsage(params: Object = {}, options: Options = {}): Promise<ResponseList<DataUsageModel>> {
        let url: string = '/credit-usage';
        if (options?.root) {
            url = options.root + url;
        }
        PaymentService.initCancelToken();
        if (options?.source) {
            PaymentService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: PaymentService.source?.token })
                .then(res => {
                    let data = new ResponseList<DataUsageModel>(res.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}