import { Settings } from "@material-ui/icons";
import BellIcon from "../Components/Icons/BellIcon";
import SimIcon from "../Components/Icons/SimIcon";
import UserIcon from "../Components/Icons/UserIcon";
import WalletIcon from "../Components/Icons/WalletIcon";
import { MODULES } from "./Constants"

export class Menu {
    public path: string;
    public icon: any;
    public title: string;
    public modules: string[];
    public submenus?: Menu[];
    public activeClass: string;
}

// { path: '/recharge', icon: 'iconsminds-billing mb-2', title: 'Recharge', name: MODULES.RECHARGE, activeClass: 'active' },
// { path: '/groups', icon: 'iconsminds-user mb-2', title: 'Group Management', name: MODULES.GROUP_MANAGEMENT, activeClass: 'active' },
// { path: '/prices', icon: 'iconsminds-money-bag mb-2', title: 'Pricing Management', name: MODULES.PRICING_MANAGEMENT, activeClass: 'active' },
// { path: '/sims', icon: 'iconsminds-communication-tower-2 mb-2', title: 'Sims Management', name: MODULES.SIMS_MANAGEMENT, activeClass: 'active' },
// { path: '/roles', icon: 'iconsminds-user mb-2', title: 'Role Management', name: MODULES.ROLE_MANAGEMENT, activeClass: 'active' },
// { path: '/employees', icon: 'iconsminds-user mb-2', title: 'Employee Management', name: MODULES.EMPLOYEE_MANAGEMENT, activeClass: 'active' },
// { path: '/companies', icon: 'iconsminds-post-office mb-2', title: 'Company Management', name: MODULES.COMPANY_MANAGEMENT, activeClass: 'active' },
// { path: '/automation', icon: 'iconsminds-gear mb-2', title: 'Automation', name: MODULES.AUTOMATION, activeClass: 'active' },
// { path: '/usage', icon: 'iconsminds-billing mb-2', title: 'Usage', name: MODULES.USAGE, activeClass: 'active' },
export const MENUS: Array<Menu> = [
    { path: '/recharge', icon: <WalletIcon />, title: 'Recharge', modules: [MODULES.RECHARGE], activeClass: 'activeLink' },
    { path: '/user-management', icon: <UserIcon />, title: 'User management', modules: [MODULES.GROUP_MANAGEMENT, MODULES.ROLE_MANAGEMENT, MODULES.EMPLOYEE_MANAGEMENT], activeClass: 'activeLink' },
    { path: '/prices', icon: <WalletIcon />, title: 'Pricing', modules: [MODULES.PRICING_MANAGEMENT], activeClass: 'activeLink' },
    { path: '/sims', icon: <SimIcon />, title: 'Sims', modules: [MODULES.SIMS_MANAGEMENT], activeClass: 'activeLink' },
    { path: '/companies', icon: <SimIcon />, title: 'Company', modules: [MODULES.COMPANY_MANAGEMENT], activeClass: 'activeLink' },
    { path: '/automation', icon: <Settings />, title: 'Automation', modules: [MODULES.AUTOMATION], activeClass: 'activeLink' },
    {
        path: '/customer-usage-report', icon: <Settings />, title: 'Reports', modules: [MODULES.REPORTS], activeClass: 'activeLink', submenus: [
            { path: '/customer-usage-report', icon: '', title: 'Customer Usage Report', modules: [MODULES.CUSTOMER_USAGE_REPORT], activeClass: 'activeLink' },
            { path: '/provider-data-usage-report', icon: '', title: 'Provider Data Usage Report', modules: [MODULES.PROVIDER_DATA_USAGE_REPORT], activeClass: 'activeLink' },
            { path: '/geographic-data-consumption-report', icon: '', title: 'Geographic Data Consumption Report', modules: [MODULES.GEOGRAPHIC_DATA_CONSUMPTION_REPORT], activeClass: 'activeLink' },
            { path: '/data-usage-trends-report', icon: '', title: 'Data Usage Trend Report', modules: [MODULES.DATA_USAGE_TREND_REPORT], activeClass: 'activeLink' },

            { path: '/device-usage-report', icon: '', title: 'Device Usage Report', modules: [MODULES.DEVICE_USAGE_REPORT], activeClass: 'activeLink' },
            { path: '/customer-provider-preference-report', icon: '', title: 'Customer Provider Preference Report', modules: [MODULES.CUSTOMER_PROVIDER_PREFERENCE_REPORT], activeClass: 'activeLink' },
            { path: '/cost-analysis-report', icon: '', title: 'Cost Analysis Report', modules: [MODULES.COST_ANALYSIS_REPORT], activeClass: 'activeLink' },
            { path: '/high-usage-customer-report', icon: '', title: 'High Usage Customer Report', modules: [MODULES.HIGH_USAGE_CUSTOMER_REPORT], activeClass: 'activeLink' },
            { path: '/provider-performance-report', icon: '', title: 'Provider Performance Report', modules: [MODULES.PROVIDER_PERFORMANCE_REPORT], activeClass: 'activeLink' },
            { path: '/provider-cost-analysis-report', icon: '', title: 'Provider Cost Analysis Report', modules: [MODULES.PROVIDER_COST_ANALYSIS_REPORT], activeClass: 'activeLink' },
            { path: '/customer-detail-report', icon: '', title: 'Customer Detail Report', modules: [MODULES.CUSTOMER_DETAIL_REPORT], activeClass: 'activeLink' },
            { path: '/countrywise-total-consumption-report', icon: '', title: 'Countrywise Total Consumption Report', modules: [MODULES.COUNTRYWISE_TOTAL_CONSUMPTION_REPORT], activeClass: 'activeLink' },
            { path: '/provider-total-consumption-report', icon: '', title: 'Provider Total Consumption Report', modules: [MODULES.PROVIDER_TOTAL_CONSUMPTION_REPORT], activeClass: 'activeLink' }
        ]
    }
    // { path: '/billings', icon: 'iconsminds-gear mb-2', title: 'Billings', name: MODULES.BILLINGS, activeClass: 'active' },
    // { path: '/resellers', icon: 'iconsminds-user mb-2', title: 'Reseller Management', name: MODULES.RESELLER_MANAGEMENT, activeClass: 'active' },
];

export const COUTOMER_MENUS: Array<Menu> = [
    { path: '/branches', icon: <UserIcon />, title: 'Branch Management', modules: [MODULES.BRANCH_MANAGEMENT], activeClass: 'activeLink', submenus: [] },
    { path: '/usage', icon: <WalletIcon />, title: 'Usage', modules: [MODULES.USAGE], activeClass: 'activeLink', submenus: [] },
    // { path: '/users', icon: 'iconsminds-user mb-2', title: 'User Management', name: MODULES.USER_MANAGEMENT, activeClass: 'active' },
];