import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'


export class LogoIcon extends Component<Props, States> {
    static defaultProps = {
        width: 200,
        height: 66
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.colors.textColor;

        return (
            <SVGContainer {...restProps}>
                {theme.type === 'dark' ? (<svg width={width} height={height} color={color} viewBox="0 0 200 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.3229 29.7015C33.3229 38.9265 27.0221 46.3965 16.629 46.3965C6.23586 46.3965 0 38.9265 0 29.7015C0 20.5426 6.36577 13.0076 16.564 13.0076C26.7622 13.0076 33.3229 20.5426 33.3229 29.7015ZM7.92474 29.7015C7.92474 34.5733 10.8478 39.1203 16.629 39.1203C22.4101 39.1203 25.3332 34.5733 25.3332 29.7015C25.3332 24.8947 21.9554 20.2178 16.629 20.2178C10.9128 20.2178 7.92474 24.8947 7.92474 29.7015Z" fill="currentColor" />
                    <path d="M62.3585 45.682V28.922C62.3585 24.0503 59.6953 20.3477 54.6287 20.3477C49.7569 20.3477 46.4441 24.44 46.4441 29.3118V45.682H38.5833V13.5922H45.6646L46.1843 17.9443C49.4321 14.7614 52.68 13.1375 56.7073 13.1375C64.2423 13.1375 70.2833 18.7888 70.2833 28.8571V45.682H62.3585Z" fill="currentColor" />
                    <path d="M110.362 0.276062V45.682H102.957L102.437 41.2649C99.9675 45.0974 96.0062 46.3965 92.1077 46.3965C82.6889 46.3965 75.6086 40.1607 75.6086 29.6366C75.6086 18.5939 82.559 12.8777 91.9128 12.8777C95.2906 12.8777 100.553 14.6965 102.437 18.0093V0.276062H110.362ZM83.5334 29.6366C83.5334 35.2239 87.5607 39.0553 92.6273 39.0553C97.629 39.0553 101.916 35.4177 101.916 29.6366C101.916 24.0503 97.629 20.2828 92.6273 20.2828C87.5607 20.2828 83.5334 23.8554 83.5334 29.6366Z" fill="currentColor" />
                    <path d="M125.885 4.62818C125.885 10.7991 116.531 10.7991 116.531 4.62818C116.531 -1.54273 125.885 -1.54273 125.885 4.62818ZM117.246 13.5273V45.6809H125.171V13.5273H117.246Z" fill="currentColor" />
                    <path d="M157.454 10.4094L163.3 14.8264L159.728 19.3734C162.196 22.1666 163.106 25.3494 163.106 28.8571C163.106 32.8206 161.612 38.4068 156.35 40.8103C161.677 43.4735 162.976 47.3059 162.976 51.3982C162.976 60.2323 156.22 65.6887 146.931 65.6887C137.643 65.6887 130.692 60.0375 130.692 51.3982H138.552C138.552 55.5555 142.384 58.2836 146.931 58.2836C151.478 58.2836 155.051 55.8153 155.051 51.3982C155.051 46.9812 150.894 44.9675 146.931 44.9675C136.928 44.9675 130.692 38.8615 130.692 28.8571C130.692 18.8527 137.967 12.6179 146.931 12.6179C149.465 12.6179 152.063 12.9427 154.271 14.4367L157.454 10.4094ZM138.552 28.8571C138.552 34.4445 142.319 37.7573 146.931 37.7573C151.478 37.7573 155.246 34.3795 155.246 28.8571C155.246 23.3347 151.478 19.8281 146.931 19.8281C142.319 19.8281 138.552 23.2708 138.552 28.8571Z" fill="currentColor" />
                    <path d="M200 29.7015C200 38.9265 193.699 46.3965 183.306 46.3965C172.913 46.3965 166.677 38.9265 166.677 29.7015C166.677 20.5426 173.043 13.0076 183.241 13.0076C193.439 13.0076 200 20.5426 200 29.7015ZM174.602 29.7015C174.602 34.5733 177.525 39.1203 183.306 39.1203C189.087 39.1203 192.01 34.5733 192.01 29.7015C192.01 24.8947 188.632 20.2178 183.306 20.2178C177.59 20.2178 174.602 24.8947 174.602 29.7015Z" fill="currentColor" />
                </svg>) : (

                    <svg width={width} height={height} viewBox="0 0 200 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.3229 29.7015C33.3229 38.9264 27.0221 46.3965 16.629 46.3965C6.23586 46.3965 0 38.9264 0 29.7015C0 20.5426 6.36577 13.0076 16.564 13.0076C26.7622 13.0076 33.3229 20.5426 33.3229 29.7015ZM7.92474 29.7015C7.92474 34.5732 10.8478 39.1202 16.629 39.1202C22.4101 39.1202 25.3332 34.5732 25.3332 29.7015C25.3332 24.8947 21.9554 20.2178 16.629 20.2178C10.9128 20.2178 7.92474 24.8947 7.92474 29.7015Z" fill="url(#paint0_linear_621_3141)" />
                        <path d="M62.3586 45.6819V28.922C62.3586 24.0502 59.6954 20.3477 54.6287 20.3477C49.757 20.3477 46.4442 24.4399 46.4442 29.3117V45.6819H38.5833V13.5922H45.6647L46.1843 17.9443C49.4322 14.7614 52.68 13.1375 56.7074 13.1375C64.2423 13.1375 70.2833 18.7887 70.2833 28.857V45.6819H62.3586Z" fill="url(#paint1_linear_621_3141)" />
                        <path d="M110.362 0.276123V45.682H102.957L102.437 41.265C99.9676 45.0974 96.0063 46.3966 92.1078 46.3966C82.689 46.3966 75.6087 40.1607 75.6087 29.6366C75.6087 18.594 82.5591 12.8778 91.9129 12.8778C95.2907 12.8778 100.553 14.6965 102.437 18.0093V0.276123H110.362ZM83.5335 29.6366C83.5335 35.224 87.5608 39.0554 92.6274 39.0554C97.6291 39.0554 101.916 35.4178 101.916 29.6366C101.916 24.0503 97.6291 20.2828 92.6274 20.2828C87.5608 20.2828 83.5335 23.8555 83.5335 29.6366Z" fill="url(#paint2_linear_621_3141)" />
                        <path d="M125.885 4.62818C125.885 10.7991 116.531 10.7991 116.531 4.62818C116.531 -1.54273 125.885 -1.54273 125.885 4.62818ZM117.246 13.5273V45.6809H125.171V13.5273H117.246Z" fill="url(#paint3_linear_621_3141)" />
                        <path d="M157.454 10.4093L163.3 14.8264L159.728 19.3733C162.196 22.1665 163.106 25.3494 163.106 28.857C163.106 32.8205 161.612 38.4068 156.35 40.8102C161.677 43.4734 162.976 47.3059 162.976 51.3982C162.976 60.2323 156.22 65.6887 146.931 65.6887C137.643 65.6887 130.692 60.0374 130.692 51.3982H138.552C138.552 55.5554 142.384 58.2836 146.931 58.2836C151.478 58.2836 155.051 55.8152 155.051 51.3982C155.051 46.9811 150.894 44.9674 146.931 44.9674C136.928 44.9674 130.692 38.8615 130.692 28.857C130.692 18.8526 137.967 12.6178 146.931 12.6178C149.465 12.6178 152.063 12.9426 154.271 14.4366L157.454 10.4093ZM138.552 28.857C138.552 34.4444 142.319 37.7572 146.931 37.7572C151.478 37.7572 155.246 34.3795 155.246 28.857C155.246 23.3346 151.478 19.828 146.931 19.828C142.319 19.828 138.552 23.2708 138.552 28.857Z" fill="url(#paint4_linear_621_3141)" />
                        <path d="M200 29.7015C200 38.9264 193.699 46.3965 183.306 46.3965C172.913 46.3965 166.677 38.9264 166.677 29.7015C166.677 20.5426 173.043 13.0076 183.241 13.0076C193.439 13.0076 200 20.5426 200 29.7015ZM174.602 29.7015C174.602 34.5732 177.525 39.1202 183.306 39.1202C189.087 39.1202 192.01 34.5732 192.01 29.7015C192.01 24.8947 188.632 20.2178 183.306 20.2178C177.59 20.2178 174.602 24.8947 174.602 29.7015Z" fill="url(#paint5_linear_621_3141)" />
                        <defs>
                            <linearGradient id="paint0_linear_621_3141" x1="196.226" y1="2.91327" x2="-4.55564" y2="66.9207" gradientUnits="userSpaceOnUse">
                                <stop offset="0.21" stop-color="#452778" />
                                <stop offset="0.87" stop-color="#EE8234" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_621_3141" x1="196.226" y1="2.91324" x2="-4.55561" y2="66.9206" gradientUnits="userSpaceOnUse">
                                <stop offset="0.21" stop-color="#452778" />
                                <stop offset="0.87" stop-color="#EE8234" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_621_3141" x1="196.226" y1="2.91337" x2="-4.5556" y2="66.9208" gradientUnits="userSpaceOnUse">
                                <stop offset="0.21" stop-color="#452778" />
                                <stop offset="0.87" stop-color="#EE8234" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_621_3141" x1="196.226" y1="2.91332" x2="-4.55568" y2="66.9208" gradientUnits="userSpaceOnUse">
                                <stop offset="0.21" stop-color="#452778" />
                                <stop offset="0.87" stop-color="#EE8234" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_621_3141" x1="196.226" y1="2.91328" x2="-4.55559" y2="66.9207" gradientUnits="userSpaceOnUse">
                                <stop offset="0.21" stop-color="#452778" />
                                <stop offset="0.87" stop-color="#EE8234" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_621_3141" x1="196.226" y1="2.91327" x2="-4.55573" y2="66.9207" gradientUnits="userSpaceOnUse">
                                <stop offset="0.21" stop-color="#452778" />
                                <stop offset="0.87" stop-color="#EE8234" />
                            </linearGradient>
                        </defs>
                    </svg>

                )}

            </SVGContainer>
        );
    }
}

export default withTheme(LogoIcon);
