
import { Response, AutomationRuleModel, Options, ResponseList, CostAnalysisReportModel, CountrywiseTotalConsumptionReportModel, CustomerProviderPreferenceReportModel, DataUsageTrendReportModel, DeviceUsageReportModel } from '../Redux/Models';
import BaseService from './BaseService';
import { Http } from '../Http';

export default class DeviceUsageReportService extends BaseService {

    public static getList(id: string | null = null, params: Object = {}, options: Options = {}): Promise<ResponseList<DeviceUsageReportModel>> {
        let url: string = !id ? "/device-usage" : "/device-usage/" + id;
        if (options?.root) {
            url = options.root + url;
        }

        DeviceUsageReportService.initCancelToken();
        if (options?.source) {
            DeviceUsageReportService.source = options?.source
        }

        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: DeviceUsageReportService.source?.token })
                .then(res => {
                    let data = new ResponseList<DeviceUsageReportModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}
