import { BranchService } from "../../Services";
import { BaseStateModel, DataTableModel, BranchModel, UserModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';


export class EditBranchState extends BaseStateModel {
    public loading: boolean = false;
    public branchModel: BranchModel = {
        name :'',
        address :'',
        postalCode :'',
        city :'',
        country :'',
        deliveryAddress :'',
    };
}

export class BranchDetailState extends BaseStateModel {
    public branch: BranchModel = { 
        name: '',
        address: '',
        postalCode: '',
        city: '',
        country: '',
        deliveryAddress: '',
        addedBy: ''
    }
}

export class BranchListState {
    public isCreateMode: Boolean = false;
    public selectedId: number | string | null = null;
    public users: Array<UserModel>;
    public branches: Array<BranchModel> = [];
    public noData: boolean = false;
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [
            { key: 'view', icon: VisibilityIcon },
        ],
        request:BranchService.getList,
        headers:[
            { key:'i', label: '#', isSort: false },
            { key:'name', label: 'Name', isSort: true },
            { key:'country', label: 'Country', isSort: true },
            { key:'city', label: 'City', isSort: true },
            { key:'postalCode', label: 'Postal Code', isSort: true },
        ]
    }
}
