

import { ChangeEvent, Component, MouseEvent, FocusEvent, Fragment } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { countries, Country } from '../../Helpers/Countries';
import { CountryToFlag } from '../../Helpers/String';
import { Props, States } from '../../Redux/Models';
import { TextField } from '@material-ui/core'
import { EditIcon, SaveIcon } from '../../Helpers/Icons';
import { IconButton, InputHolder, InputActions } from '../../Helpers/Styled';
import PencilIcon from '../Icons/PencilIcon';

export default class InputField extends Component<Props, States> {
    public state: States = {
        editable: false,
        disabled: false,
    };

    public constructor(props: Props) {
        super(props);
        this.state.editable = !props.disabled && !!props.editable;
        this.state.disabled = !props.disabled && !!props.editable;
    }

    public static defaultProps = {
        className: "w-100",
        error: false,
        helperText: "",
        label: "input",
        name: "Input",
        onChange: (event: ChangeEvent<HTMLInputElement>) => { },
        select: false,
        value: "",
        editable: false,
        onSave: (v: string) => { }
    }

    public onEditClick = (e: MouseEvent<HTMLButtonElement>) => {
        this.setState((prevState: States) => prevState.disabled = false);
    };

    public onSaveClick = (e: MouseEvent<HTMLButtonElement>) => {
        const { onSave } = this.props;
        this.setState((prevState: States) => prevState.disabled = true);
        if (onSave) {
            onSave();
        }
    };

    public handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
        this.setState((prevState: States) => prevState.disabled = true);
    };

    public handleAutocompleteChange = (e: ChangeEvent<any>, country: Country | null) => {
        const { onChange, name } = this.props;
        if (onChange) {
            onChange({ target: { name: name, value: country?.name } });
        }
    };

    public handleInputChange = (e: ChangeEvent<any>, value: string) => {
        const { onChange, name } = this.props;
        if (onChange && value) {
            onChange({ target: { name: name, value: value } });
        }
    };

    public render() {
        const { editable: a, children, className, onSave, value, onChange, ...props } = this.props;
        const { editable, disabled } = this.state;

        console.log('component ', value);
        return editable ? (
            <InputHolder className={className}>
                <InputActions className="input-actions">
                    {disabled && <IconButton onClick={this.onEditClick}><PencilIcon height={20} width={20} /></IconButton>}
                    {!disabled && <IconButton onClick={this.onSaveClick}><SaveIcon fontSize="small" /></IconButton>}
                </InputActions>
                <Autocomplete
                    fullWidth
                    disabled={disabled}
                    inputValue={value}
                    onChange={this.handleAutocompleteChange}
                    onInputChange={this.handleInputChange}
                    getOptionLabel={(option: Country) => option.name ?? ""}
                    renderOption={(option: Country) => (
                        <Fragment>
                            <img src={CountryToFlag(option.code)} height="24" alt="" />
                            &nbsp; {option.name}
                        </Fragment>
                    )}
                    options={countries}
                    renderInput={(params) => <TextField {...params} {...props} fullWidth />}
                />
            </InputHolder>
        ) : (
            <Autocomplete
                fullWidth
                disabled={disabled}
                inputValue={value}
                onChange={this.handleAutocompleteChange}
                onInputChange={this.handleInputChange}
                getOptionLabel={(option: Country) => option.name ?? ""}
                renderOption={(option: Country) => (
                    <Fragment>
                        <img src={CountryToFlag(option.code)} height="24" alt="" />
                        &nbsp; {option.name}
                    </Fragment>
                )}
                options={countries}
                renderInput={(params) => <TextField {...params} {...props} fullWidth />}
            />
        );
    }
}

