import styled, { css } from "styled-components";
import { Variables } from "../../Configs/Variables";
import { PropsGlobalStyle } from "../../Redux/Models";

export const FilterContainer = styled.div`
	${(props: PropsGlobalStyle) => {
		return css`
		&::-webkit-scrollbar
		{
			width: 5px;
			background-color: ${props.mode === 'light' ? Variables.white : Variables.darkGrey} !important;
		}
	
		&::-webkit-scrollbar-thumb{
			border-radius: 10px !important;
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
			background-color: ${props.mode === 'light' ? Variables.primary : Variables.secondary} !important;
		}
`}};
`;