import { Component } from 'react';
import { RouteInterface, routes } from '../Routes/Auth';
import { Logo, TopPartPattern, BottomPartPattern, TopPartPatternM, BottomPartPatternM } from '../Helpers/Images';
import { Switch, Route, Redirect } from "react-router-dom";
// import { AuthLeftHead } from '../Helpers/Styled';
import { Container, Row, Col } from 'reactstrap';
import { Props } from '../Redux/Models';


class HomeLayout extends Component {
    public render() {
        return (
            <div className="auth-part d-flex">
                <img src={TopPartPattern} className="img-fluid patterns dt" alt="" />
                <img src={TopPartPatternM} className="img-fluid patterns mt" alt="" />
                <Container className="mx-auto my-auto">
                    <Row className="justify-content-center">
                        <Col lg="10" md="12">
                            <div className="form-holder">
                                <div className="left-part white flex-center">
                                    <h1><span style={{ color: '#fff' }}>Stay </span> <br />
                                        <span style={{ color: '#fff' }}>Connected</span></h1>
                                </div>
                                <div className="right-part">
                                    <div className="text-center mb-4">
                                        <img width="200" height="66" className="img-fluid" src={Logo} alt="" />
                                    </div>
                                    <Switch>
                                        {routes.map((route: RouteInterface, idx) => {
                                            const title = { title: route.name }
                                            return route.component ? (
                                                <Route
                                                    key={idx}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    render={(props: Props) => <route.component {...this.props} {...props} {...title} />}
                                                />
                                            ) : null;
                                        })}
                                        <Redirect from="/" to="/login" />
                                    </Switch>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <img src={BottomPartPattern} className="img-fluid patterns db" alt="" />
                <img src={BottomPartPatternM} className="img-fluid patterns mb" alt="" />
            </div>
        )
    }
}

export default HomeLayout;
