import { Component, ChangeEvent } from 'react';
import { Paper, Button, Tabs, Tab } from '@material-ui/core';
import { ArrowBackIcon } from '../../Helpers/Icons';
import { Row, Col } from 'reactstrap';
import { Props, States } from '../../Redux/Models';
import { RESELLER_TABS, TabModel, COMPANY_TABS } from '../../Configs/Tabs';
import { HasKey } from '../../Helpers/Object';
import { FindIndex } from '../../Helpers/Array';
import { StyledHeaderPart } from './style';
import classNames from 'classnames';
import AddIcon from '../Icons/AddIcon';

export default class MainBody extends Component<Props, States> {

    public state: States = {
        selectedTab: 0,
        selected: 'Info',
        tabs: []
    };

    public static defaultProps = {
        tab: '',
        showTab: false,
    };

    public constructor(props: Props) {
        super(props);
        const { match } = props;
        if (match) {
            switch (true) {
                case 'reseller' === props.tab || HasKey(match.params, 'reseller'):
                    const arry = JSON.parse(JSON.stringify(RESELLER_TABS));
                    this.state.tabs = arry.map((tab: TabModel) => {
                        if ('reseller' === props.tab) {
                            tab.path = tab.path.replace(':id', match.params.id)
                        }
                        if (HasKey(match.params, 'reseller')) {
                            tab.path = tab.path.replace(':id', match.params.reseller)
                        }
                        return tab;
                    });
                    let index = FindIndex(this.state.tabs.slice().reverse(), (tab: TabModel) => {
                        return match.url.includes(tab.path)
                    });
                    if (index >= 0) {
                        this.state.selectedTab = this.state.tabs.length - index - 1;
                    }
                    break

                case 'companies' === props.tab || HasKey(match.params, 'company'):
                    const arry2 = JSON.parse(JSON.stringify(COMPANY_TABS));
                    this.state.tabs = arry2.map((tab: TabModel) => {
                        if ('companies' === props.tab) {
                            tab.path = tab.path.replace(':id', match.params.id)
                        }
                        if (HasKey(match.params, 'company')) {
                            tab.path = tab.path.replace(':id', match.params.company)
                        }
                        return tab;
                    });
                    let index2 = FindIndex(this.state.tabs.slice().reverse(), (tab: TabModel) => {
                        return match.url.includes(tab.path)
                    });
                    if (index2 >= 0) {
                        this.state.selectedTab = this.state.tabs.length - index2 - 1;
                    }
            }
        }
    }
    
    public handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        const { history } = this.props;
        const { tabs } = this.state;
        this.setState((prevState: States) => prevState.tab = newValue, () => {
            if (tabs[newValue]) {
                const tab: TabModel = tabs[newValue];
                if (tab.path) {
                    history.push(tab.path);
                }
            }
        });
    };

    public render() {
        const { children, isCreateMode, title, onBackPress, onAddPress, onAction } = this.props;
        const { selectedTab, tabs, selected } = this.state;

        return (
            <div>
                {/* <Row className="mb-4 mt-4">
                    <Col><h5 className="h5 pt-1">{title}</h5></Col>
                    {title === "Recharge" &&
                        <Col><h5 className="h5 pt-1">{"Wallet: " + user.balance + " €"}</h5></Col>
                    }

                    <Col className="text-right">
                        {onBackPress && <Button variant="contained" onClick={onBackPress} startIcon={<ArrowBackIcon />}></Button>}
                    </Col>
                </Row> */}
                {onAddPress && !isCreateMode && <Button className="ml-2 p-4" style={{
                    position: 'fixed',
                    borderRadius: '100%',
                    zIndex: 1000,
                    right: '0.5rem',
                    bottom: '6rem'
                }} variant="contained" color="primary" onClick={onAddPress}><AddIcon width={30} height={30} /></Button>}

                {onAction}
                {!!tabs.length && (
                    <>
                    </>
                    // <Row>
                    //     <Col className="pb-1">
                    //         <Tabs value={selectedTab} onChange={this.handleChange} variant="fullWidth">
                    //             {
                    //                 tabs.map((tab: TabModel, index: number) => (
                    //                     <Tab key={index} label={tab.title} style={{backgroundColor: 'red', margin: '5px'}} />
                    //                 ))
                    //             }
                    //         </Tabs>
                    //     </Col>
                    // </Row>
                    // <StyledHeaderPart className="my-4">
                    //     {tabs.map((tab: TabModel, index: number) => (
                    //         <Button key={index} className={classNames({ active: !this.props.history.location.state ? (!this.props.history.location.state && !index) ? true : false : this.props.history.location.state.key === tab.title })} fullWidth size='large' onClick={(e: ChangeEvent<any>) => {
                    //             this.handleChange(e, index, tab.title);
                    //         }} variant='outlined' color='secondary'>{tab.title}</Button>
                    //     ))}
                    // </StyledHeaderPart>
                )
                }
                <Row>
                    <Col>
                        {/* <Paper> */}
                        {children}
                        {/* </Paper> */}
                    </Col>
                </Row>
            </div>
        );
    }
}