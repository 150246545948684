import { BaseStateModel, UserModel } from "../Models";

export class LoginState extends BaseStateModel {
    public loading: boolean = false;
    public loginModel: UserModel = {
        email: '',
        password: '',
        totp: ''
    }
    public isCheckOTP: boolean = false;
}

export class ResetState extends BaseStateModel {
    public field: string = '';
    public message: string = '';
    public loading: boolean = false;

    public resetModel: UserModel = {
        token: '',
        password: '',
        confirmPassword: ''
    }
}

export class ForgetState extends BaseStateModel {
    public loading: boolean = false;
    public forgetModel: UserModel = {
        email: ''
    }
}

export class ProfileState extends BaseStateModel {
    public loading: boolean = false;
    public uploadPercentage: number = 0;
    public uploadProgres: string = '';
    public isChangePassword: boolean = false;
    public field: string = '';
    public message: string = '';
    public showModal: boolean = false;

    public userModel: UserModel = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
       
    }

    public changePasswordModel: UserModel = {
        currentPassword: '',
        password: '',
        confirmPassword: ''
    }

    public mfaModel: any = {
        base32: "",
        qr: "",
        code: ""
    }
}