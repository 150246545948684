import { Component, MouseEvent } from "react";
import { EditIcon, SaveIcon } from "../../Helpers/Icons";
import { IconButton, InputActions, InputHolder } from "../../Helpers/Styled";
import { Props, States } from "../../Redux/Models";
import { Container, InputValue, PlaceholderContainer, PlaceholderValue } from "./styled";


export default class CInputField extends Component<Props, States> {
    public state: States = {
        editable: false,
        disabled: false
    };

    public constructor(props: Props) {
        super(props);
        this.state.editable = !props.disabled && !!props.editable;
        this.state.disabled = !props.disabled && !!props.editable ? (!props.disabled && !!props.editable) : props.disabled;
    }
    public onEditClick = (e: MouseEvent<HTMLButtonElement>) => {
        this.setState((prevState: States) => prevState.disabled = false);
    };

    public onSaveClick = (e: MouseEvent<HTMLButtonElement>) => {
        const { onSave } = this.props;
        this.setState((prevState: States) => prevState.disabled = true);
        if (onSave) {
            onSave();
        }
    };

    public render() {
        const { label, value, onChange, className, defaultValue } = this.props;
        const { editable, disabled } = this.state;
        return editable ? (
            <InputHolder className={className}>
                <InputActions className="input-actions">
                    {disabled && <IconButton onClick={this.onEditClick}><EditIcon fontSize="small" /></IconButton>}
                    {!disabled && <IconButton onClick={this.onSaveClick}><SaveIcon fontSize="small" /></IconButton>}
                </InputActions>
                <Container>
                    <InputValue onChange={onChange} defaultValue={defaultValue} value={value} disabled={disabled}></InputValue>
                    <PlaceholderContainer>
                        <PlaceholderValue>{label}</PlaceholderValue>
                    </PlaceholderContainer>
                </Container>
            </InputHolder>
        ) : (
            <InputValue onChange={onChange} defaultValue={defaultValue} value={value} disabled={disabled}></InputValue>
        );
    }
}