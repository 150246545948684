import { createTheme } from '@material-ui/core/styles';
const commonColor = {
  white: '#fff',
  black: '#000',
  orange: '#EE8234',
  purple: '#452778',
}

const dark = {
  ...commonColor,
  bodyBgColor: '#2C2C2C',
  componentBgColor: '#373737',
  textColor: '#FAFAFA',
  primaryColor: commonColor.orange
}

const light = {
  ...commonColor,
  bodyBgColor: '#efecec',
  componentBgColor: '#ffffff',
  textColor: '#232323',
  primaryColor: commonColor.purple
}


export let colors = {
  ...light
}


export const PrimaryTheme = (type: string = 'light') => {
  colors = type === 'light' ? { ...light } : { ...dark };
  return createTheme({
    palette: {
      primary: {
        main: colors.orange,
        contrastText: colors.white
      },
      secondary: {
        main: colors.purple,
        contrastText: colors.white
      },
      background: {
        paper: colors.componentBgColor
      },
      text: {
        primary: colors.textColor
      },
    },

    typography: {
      fontFamily: `'Be Vietnam Pro', sans-serif`,
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600
    },

    overrides: {
      MuiButton: {
        root: {
          borderRadius: 10,
          borderWidth: '3px !important',
          boxShadow: 'unset !important',
          fontWeight: 600,
          fontSize: 16,
          textTransform: 'unset',
          '&.with-toggle-moode': {
            borderColor: `${colors.primaryColor} !important`,
            color: `${colors.textColor} !important`
          }
        },

        containedPrimary: {
          border: `3px solid ${colors.orange}`
        },

        outlinedPrimary: {
          color: colors.textColor,
          border: `3px solid ${colors.orange}`
        },

        containedSecondary: {
          border: `3px solid ${colors.purple}`
        },

        outlinedSecondary: {
          color: colors.textColor,
          border: `3px solid ${colors.purple}`,

          '&.active': {
            backgroundColor: `${colors.purple} !important`,
            color: `${colors.white} !important`
          }
        }
      },

      MuiCheckbox: {
        root:{
          color: `${colors.primaryColor} !important`,
        }
      },


      MuiCard: {
        root: {
          borderRadius: 10,
          padding: '1vw',
          boxShadow: 'unset !important'
        },
      },

      MuiCardHeader: {
        title: {
          fontSize: '28px !important',
          fontWeight: 200,
        }
      },

      MuiCardActions: {
        root: {
          '&.grid': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '20px'
          }
        }
      },

      MuiInputBase: {
        root: {
          color: `${colors.textColor} !important`,
          fontSize: '14px !important',
          // fontWeight: '400 !important',
          fontWeight: 400
          // '&:disabled, &.disabled, &:readonly, &.disabled': {
          //   color: `${colors.textColor} !important`,
          // }
        },
        formControl: {
          borderRadius: '10px !important',
          borderWidth: '3px !important',
          fontWeight: 600,
          fontSize: 16
        },
      },

      MuiFormControl: {
        root: {
          color: `${colors.textColor} !important`
        }
      },
      // For input lebel 
      MuiFormLabel: {
        root: {
          color: `${colors.textColor} !important`
        }
      },
      // For input outlines 
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: `${colors.primaryColor} !important`,
          borderWidth: 2,
        }
      },

      MuiTypography: {
        root: {
          color: colors.textColor
        },

        h1: {
          fontSize: '48px',
          fontWeight: 600,
        },

        h2: {
          fontSize: '40px',
          fontWeight: 600,
        },

        h3: {
          fontSize: '32px',
          fontWeight: 600,
        },

        h4: {
          fontSize: '24px',
          fontWeight: 600,
        },

        h5: {
          fontSize: '16px',
          fontWeight: 600,
        },

        h6: {
          fontSize: '12px',
          fontWeight: 600,
        }
      }
    },
  });
}