import { Component } from 'react';
import { CircularProgress, Theme, withStyles } from '@material-ui/core';
import { Styles } from '@material-ui/core/styles/withStyles';
import { Props } from '../Redux/Models';

const styles:Styles<Theme,Props> = {
  circularProgress: {
    position: 'fixed',
    top: 'calc(50% - 45px)',
    left: 'calc(50% - 45px)',
  }
};


class Loading extends Component{
    public render() {
        const { classes }:Props = this.props;
        return (<CircularProgress className={classes.circularProgress} size={90} thickness={1} color="secondary" />);
    }
}

export default (withStyles(styles)(Loading));
