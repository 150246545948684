import { AuthService } from "../../Services";
import { BaseStateModel, DataTableModel } from "../Models/Base.Models";
import { ModuleModel, ModulePermissionModel, PermissionModel, RoleModel } from "../Models/Role.Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { AutomationRuleModel } from "../Models";

export class RoleListState extends BaseStateModel {
    public roles: Array<RoleModel> = [];
    public permissions: Array<PermissionModel> = [];
    public selectedId: string | number | null = null;
    public reseller: string | null = null;
    public isCreateMode: Boolean = false;
    
    public loading: boolean = false;
    public noData: boolean = false;
    public showAutomationModal: boolean = false;
    public automations: Array<AutomationRuleModel> = [];
    public selectedItems: Array<number> = [];
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }

    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '20%',
        actions: [
            { key: 'view', icon: VisibilityIcon },
        ],
        request: AuthService.getRoles,
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '40%' } },
            { key: 'name', label: 'Name', isSort: true, style: { width: '50%' } }
        ]
    }
}

export class RoleViewState extends BaseStateModel {
    public role: RoleModel = {
        name: '',
        status: false,
        permissions: [],
        createdAt: '',
        UpdatedAt: '',
    };
    public permissions: Array<PermissionModel> = [];
    public modules: Array<ModuleModel> = [];
    public modulePermissions: Array<ModulePermissionModel> = [];
}

export class RoleDetailsState extends BaseStateModel {
    public role: RoleModel = {
        name: '',
    };
    public permissions: Array<PermissionModel> = [];
    public modules: Array<ModuleModel> = [];
    public modulePermissions: Array<ModulePermissionModel> = [];
    public loading : boolean = false;
}