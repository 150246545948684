
import BaseService from './BaseService';
import { Http } from '../Http';
import { Response, ResponseList, BranchModel, Options } from '../Redux/Models';

export default class BranchService extends BaseService {

    public static getList(params: Object = {}, options:Options = {}) : Promise<ResponseList<BranchModel>> {
        let url:string = '/branches'; 
        if(options?.root){
            url = options.root + url;
        }
        BranchService.initCancelToken();
        if(options?.source){
            BranchService.source =  options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: BranchService.source?.token })
                .then(res => {
                    let data = new ResponseList<BranchModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: Object = {}, options:Options = {}): Promise<Response<BranchModel>> {
        this.initCancelToken();
        let url = '/branches'
        if(options?.root){
            url = options.root + url
        }
        return new Promise((resolve, reject) => {
            Http.post(url, params, { cancelToken:this.source?.token })
                .then(res => {
                    let data = new Response<BranchModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static update(id:string, params: Object = {}, options: Options = {}): Promise<Response<BranchModel>> {
        this.initCancelToken();
        let url = '/branches/'+ id
        if(options?.root){
            url = options.root + url
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken:this.source?.token })
                .then(res => {
                    let data = new Response<BranchModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static delete(id:string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url = '/branches/' + id
        if(options?.root){
            url = options.root + url
        }
        return new Promise((resolve, reject) => {
            Http.delete(url, { params, cancelToken:this.source?.token})
                .then(res => { 
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateStatus(id:string, params: Object = {}): Promise<Response<BranchModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch('/branches/' + id + '/status', params, { cancelToken:this.source?.token })
                .then(res => {
                    let data = new Response<BranchModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getById(id: String, params = {}, options:Options = {}): Promise<Response<BranchModel>> {
        this.initCancelToken();
        let url = '/branches/'+ id
        if(options?.root){
            url = options.root + url
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken:this.source?.token}).then(res => {
                let data = new Response<BranchModel>(res?.data);
                return resolve(data);
            })
            .catch(err => {
                return reject(err);
            });
        });
    }
}