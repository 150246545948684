import styled, { AnyStyledComponent, css } from "styled-components";
import { Props, PropsGlobalStyle } from "../../../Redux/Models";


export const StyledCardGrid: AnyStyledComponent = styled.div`
  ${(props: PropsGlobalStyle) => {
    return css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 25px;

        .is-details & {
            grid-template-columns: repeat(2, 1fr);
        }
  `}};
`;

export const StyledCardAndDetailsGrid: AnyStyledComponent = styled.div`
  ${(props: PropsGlobalStyle) => {
    return css`
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 25px;
        align-items: start;

        &.is-details {
            grid-template-columns: 2fr 1fr;
        }
  `}};
`;