
import { BaseStateModel, DataTableModel, BranchModel, UserModel, CustomerUsageReportModel, CompanyModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';
import { BillingService } from "../../Services";

export class GeographicDataConsumptionReportState {
    public geographicDataConsumptionReports: Array<CustomerUsageReportModel> = [];
    public companies: Array<CompanyModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        // <MSISDN> or <CUSTOMERID>, <DATAUSED>, and <TIME>
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'country', label: 'COUNTRY', isSort: true },
            { key: 'total_consumption', label: 'DATAUSED', isSort: true },
        ]
    }
}
