import styled, { css } from "styled-components";
import { Props, PropsGlobalStyle } from "../../Redux/Models";
import { Variables } from "../../Configs/Variables";
import { Divider } from "@material-ui/core";

export const StyledMenu: any = styled.div`
  ${(props: PropsGlobalStyle) => {
        return css`
        width: 300px;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        padding: 25px 0;
        background-color: ${props.theme.colors.componentBgColor};

        &&& {
            ul, li{
            list-style-type: none;
            padding: 0;
            padding-right: 10px;
        }

        .submenu {
            padding: 0;
        }

        .submenu li {
            padding-right: 0 !important;
            a {
            position: relative;
            padding-left: 56px !important;
            &::after {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: ${props.theme.colors.textColor};
                position: absolute;
                content: '';
                left: 36px;
                top: 25px;
            }

            &.activeLink::after {
                background-color: ${props.theme.colors.white};
            }
        }

            margin-top: 15px;
            .MuiButton-fullWidth {
                color: ${props.theme.colors.textColor};
                padding : 15px 30px;
                text-align: left;
                justify-content: flex-start;
                border-radius: 0 10px 10px 0;

                &:not(.mode) {
                    &:hover, &.activeSubLink {
                        color:  ${props.theme.colors.white} !important;
                    }
                }
            }
        }

        ul {
            max-height: calc(100vh - 130px);
            overflow: auto;
            
            &::-webkit-scrollbar
		    {
		    	width: 5px;
		    	background-color: ${props.mode === 'light' ? Variables.white : Variables.darkGrey} !important;
		    }
        
		    &::-webkit-scrollbar-thumb{
		    	border-radius: 10px !important;
		    	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
		    	background-color: ${props.mode === 'light' ? Variables.primary : Variables.secondary} !important;
		    }
        }
            li {
                margin-top: 25px;
                .MuiButton-fullWidth {
                    color: ${props.theme.colors.textColor};
                    padding : 15px 30px;
                    text-align: left;
                    justify-content: flex-start;
                    border-radius: 0 10px 10px 0;

                    &:not(.mode) {
                        &:hover, &.activeLink {
                        background-color: ${props.theme.colors.primaryColor};
                        color:  ${props.theme.colors.white};
                    }
                    }
                }
            }
        }
  `}};
`;

export const FooterDevider: any = styled(Divider)`
    background: linear-gradient(90deg, #452778, #ee8234) !important;
    height: 0.1rem !important;
`;