import { startCase, lowerCase, debounce } from 'lodash'
import Configs from '../Configs';

export const titleCase = (v: string): string => {
    return startCase(lowerCase(v));
}

export const ServerImage = (v: string): string => {
    return Configs.API_ROOT + v;
}

export const CountryToFlag = (isoCode: string): string => {
    //return "https://www.countryflags.io/" + isoCode + "/flat/64.png";
    return "https://cdn.ip2location.com/assets/img/flags/" + isoCode + ".png";
    //return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
}

export const Debounce = debounce;