import loadable from "@loadable/component";
import { ComponentType } from "react";
import { Fallback } from '../Components';

const LoginView = loadable(() => import("../Views/Auth/Login.View"), {
    fallback: <Fallback />
});

const ForgetPasswordView = loadable(() => import("../Views/Auth/ForgetPassword.View"), {
    fallback: <Fallback />
});

const ResetPasswordView = loadable(() => import("../Views/Auth/ResetPassword.View"), {
    fallback: <Fallback />
});

export interface RouteInterface {
    path: string;
    exact?: boolean, 
    name:string;
    component:ComponentType 
}


export const routes: Array<RouteInterface> = [
    { path: '/login', exact:true, name: 'Login', component: LoginView },
    { path: '/forget-password', exact:true, name: 'Forget Password', component: ForgetPasswordView },
    { path: '/reset-password/:token', exact:true, name: 'Reset Password', component: ResetPasswordView },
];