import { ChangeEvent, Component, MouseEvent, FocusEvent, RefObject, createRef } from 'react'
import { Props, States } from '../../Redux/Models';
import PhoneInput from './PhoneInput';
import { EditIcon, SaveIcon } from '../../Helpers/Icons';
import { IconButton, InputHolder, InputActions } from '../../Helpers/Styled';
import PencilIcon from '../Icons/PencilIcon';

export default class PhoneInputField extends Component<Props, States> {
    public submitBtn:RefObject<HTMLButtonElement>;
    public state:States = {
        editable:false,
        disabled:false
    };

    public constructor(props:Props){
        super(props);
        this.submitBtn = createRef();
        this.state.editable = !props.disabled && !!props.editable;
        this.state.disabled = !props.disabled && !!props.editable;
    }
    
    public static defaultProps = {
        className: "w-100",
        error: false,
        helperText: "",
        label: "input",
        name: "Input",
        onChange: (event:ChangeEvent<HTMLInputElement>) => {},
        select: false,
        value: "",
        editable:null,
    }

    public onEditClick = (e:MouseEvent<HTMLButtonElement>) => {
        this.setState((prevState:States)=>prevState.disabled = false);
    };

    public onSaveClick = (e:MouseEvent<HTMLButtonElement>) => {
        const { onSave } = this.props;
        this.setState((prevState:States)=>prevState.disabled = true);
        if(onSave){
            onSave();
        }    
    };

    public handleOnBlur = (e:FocusEvent<HTMLInputElement>) => {
        this.setState((prevState:States)=>prevState.disabled = true);
    };

    public render() {
        const { editable:a, children, onSave, ...props } = this.props;
        const { editable, disabled } = this.state;
        return editable ? (
            <InputHolder>
                <InputActions className="input-actions">
                    { disabled && <IconButton onClick={this.onEditClick}><PencilIcon height={20} width={20} /></IconButton> }
                    { !disabled && <IconButton onClick={this.onSaveClick}><SaveIcon fontSize="small"/></IconButton> }
                </InputActions>
                <PhoneInput {...props} disabled={disabled}/>     
            </InputHolder>
        ):(
          <PhoneInput {...props}/>
        );
    }
}
