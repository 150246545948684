import React, { useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DashboardLayout from './Layouts/Dashboard.Layout';
import HomeLayout from './Layouts/Home.Layout';
import { Props } from './Redux/Models';
import { StateModel } from './Redux/Models/Base.Models';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components'
import { PrimaryTheme, colors } from './Theme';
import GlobalStyle from './GlobalStyle';
const App = (props: Props) => {
  const getMode = () => {
    if (!localStorage.getItem('mode')) {
      localStorage.setItem('mode', 'light');
      return 'light';
    } else {
      return localStorage.getItem('mode') || 'light';
    }
  }

  const [selectedTheme, setSelectedTheme] = useState(getMode());

  const setMode = (mode: string) => {
      localStorage.setItem('mode', mode);
      setSelectedTheme(selectedTheme === 'light' ? 'dark' : 'light')
  }

  const theme = { ...PrimaryTheme(selectedTheme), colors, type: selectedTheme };
  return (
    <ThemeProvider theme={theme}>
      <StyledComponentThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Switch>
            {!props.isAuthenticated && <Route path="/" render={(rp) => <HomeLayout {...rp} {...props} />} />}
            {props.isAuthenticated && <Route path="/" render={(rp) => <DashboardLayout {...rp} {...props} toggleTheme={() => setMode(selectedTheme === 'light' ? 'dark' : 'light')} />} />}
          </Switch>
        </Router>
      </StyledComponentThemeProvider>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: StateModel) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    isExpired: state.Auth.isExpired,
  };
};

export default connect(mapStateToProps)(App);


