import { EmployeeService } from "../../Services";
import { AutomationRuleModel, BaseStateModel, DataTableModel, RoleModel, UserModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';

export class EmployeeListState {
    public users: Array<UserModel> = [];
    public roles: Array<RoleModel> = [];
    public selectedId: string | number | null = null;
    public reseller: string | null = null;
    public isCreateMode: Boolean = false;
    public loading: boolean = false;
    public noData: boolean = false;
    public showAutomationModal: boolean = false;
    public automations: Array<AutomationRuleModel> = [];
    public selectedItems: Array<number> = [];
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }

    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actionWidth: '20%',
        actions: [
            { key: 'view', icon: VisibilityIcon },
        ],
        request: EmployeeService.getList,
        headers: [
            { key: 'i', label: '#', isSort: false, style: { width: '10%' } },
            { key: 'firstName', label: 'First Name', isSort: true, style: { width: '10%' } },
            { key: 'lastName', label: 'Last Name', isSort: true, style: { width: '10%' } },
            { key: 'email', label: 'Email', isSort: true, style: { width: '25%' } },
            { key: 'phoneNumber', label: 'Phone Number', isSort: true, style: { width: '15%' } },
        ]
    }
}

export class EmployeeDetailState extends BaseStateModel {
    public tab: number = 0;
    public user: UserModel = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }
}

export class EditEmployeeState extends BaseStateModel {
    public roles: Array<RoleModel> = [];
    public loading: boolean = false;
    public userModel: UserModel = {
        
        role: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    }
}
