
import React, { Component, ChangeEvent } from 'react'
import { Col, Input, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { IconButton, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Checkbox, Paper, ListSubheader, InputAdornment, RadioGroup, FormControlLabel, Radio, styled } from '@material-ui/core';
import InputField from './../InputField';
import { Props, States } from '../../Redux/Models';
import { FilterListIcon, SearchIcon } from '../../Helpers/Icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CloseIcon } from '../../Helpers/Icons';
import { AnyStyledComponent } from 'styled-components';
import { FilterContainer } from './styled';
import { withTheme } from 'styled-components'

class Filters<T extends States> extends Component<Props, States> {
    public timeout: any;
    public state: States = {
        q: '',
        isSearch: '',
        isOpen: false,
        filters: [],
        filter: null,
        tags: [],
        mainFilters: {
            price: true,
            tags: true,
        },
        selectOne: false
    };

    public toggleDropdown() {
        this.setState(ps => ({ isOpen: !ps.isOpen }));
    }

    public toggleFilter(tag: T, type: string, isOne: boolean = false) {
        if (type === 'filter') {
            if (!isOne) {
                let i = this.state.filters.indexOf(tag);
                if (i === -1) {
                    this.setState((prevState: States) => prevState.filters = [...prevState.filters, tag], () => {
                        if (this.props.onSearch) {
                            const { isOpen, ...filters } = this.state;
                            this.props.onSearch(filters);
                        }
                    });

                }
                else {
                    let filters = this.state.filters;
                    filters.splice(i, 1);
                    this.setState((prevState: States) => prevState.filters = filters, () => {
                        if (this.props.onSearch) {
                            const { isOpen, ...filters } = this.state;
                            this.props.onSearch(filters);
                        }
                    });
                }
            }

            this.setState((prevState: States) => prevState.filter = tag, () => {

                if (this.props.onSearch) {
                    const { isOpen, ...filters } = this.state;
                    this.props.onSearch(filters);
                }
            });

        } else {
            let i = this.state.tags.indexOf(tag);
            if (i === -1) {
                this.setState((prevState: States) => prevState.tags = [...prevState.tags, tag], () => {
                    if (this.props.onSearch) {
                        const { isOpen, ...filters } = this.state;
                        this.props.onSearch(filters);
                    }
                });
            } else {
                let tags = this.state.tags;
                tags.splice(i, 1);
                this.setState((prevState: States) => prevState.tags = tags, () => {
                    if (this.props.onSearch) {
                        const { isOpen, ...filters } = this.state;
                        this.props.onSearch(filters);
                    }
                });
            }
        }
    }

    public onKeyChage = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState((prevState: States) => prevState.q = e.target.value, () => {
            if (this.props.onSearch) {
                const { isOpen, ...filters } = this.state;
                this.props.onSearch(filters);
            }
        });
    }

    public toggleSubFilter = (key: string, status: boolean) => {
        this.setState({ [key]: status });
    }

    public render() {
        const { filters, subList, data, fetchTags, theme } = this.props;

        return (
            <React.Fragment>
                <div style={{
                    position: 'relative'
                }} >
                    <div className='d-flex w-100'>
                        {this.state.isSearch ? <div>
                            <InputField name="search" last label="Search" value={this.state.q} className="w-100 mr-5" onChange={this.onKeyChage}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={(e: ChangeEvent<any>) => {
                                                this.setState({ q: '', isSearch: false });
                                                this.onKeyChage(e);
                                            }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} fullWidth />
                        </div> : <IconButton onClick={() => this.setState({ isSearch: true })}
                            color="primary">
                            <SearchIcon />
                        </IconButton>}
                        {((filters && !!filters.length) || subList) && <div data-toggle="dropdown">
                            <IconButton
                                onClick={() => this.toggleDropdown()}
                                color="primary">
                                <FilterListIcon />
                            </IconButton>
                        </div>}
                    </div>
                    <div style={{
                        top: '3rem',
                        right: '2%',
                        position: 'absolute',
                        zIndex: 1000
                    }}>
                        <div className="flex-center justify-content-end">
                            {((filters && !!filters.length) || subList) && <div>
                                {this.state.isOpen && <div style={{ padding: 0, border: 0, margin: 0 }}>
                                    {/* {
                                                         filters.map((filters: T, i: number) =>
                                                             <DropdownItem key={i} onClick={() => this.toggleFilter(filters)}>{filters.name}</DropdownItem>
                                                         )
                                                     } */}
                                    <Paper style={{ width: '15rem' }}>
                                        {subList ?
                                            <List dense >
                                                <ListItem button onClick={() => this.toggleSubFilter('price', !this.state.price)}>
                                                    <ListItemText primary={'Prices'} />
                                                </ListItem>
                                                {this.state.price && <FilterContainer mode={theme.type} style={!data.tags.length ? {} : { height: '30vh', overflowY: 'scroll' }} id="scrollableDiv">
                                                    {!data.prices.length ? <small className='pl-5 text-secondary'>No Prices Found!</small>
                                                        :
                                                        <List dense >
                                                            {
                                                                data.prices.map((filter: T, i: number) => {
                                                                    const labelId = `checkbox-list-secondary-label-${i}`;
                                                                    return (
                                                                        <ListItem className='pl-5' key={i} button onClick={() => this.toggleFilter(filter, 'filter')}>
                                                                            <ListItemText id={labelId} primary={filter.name} />
                                                                            <ListItemSecondaryAction>
                                                                                <Checkbox
                                                                                    edge="end"
                                                                                    color="primary"
                                                                                    onChange={() => this.toggleFilter(filter, 'filter')}
                                                                                    checked={this.state.filters.indexOf(filter) !== -1}
                                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                                />
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    );
                                                                })
                                                            }
                                                        </List>}
                                                </FilterContainer>}
                                                <ListItem button onClick={() => this.toggleSubFilter('tagFilter', !this.state.tagFilter)}>
                                                    <ListItemText primary={'Tags'} />
                                                </ListItem>
                                                {this.state.tagFilter && <FilterContainer mode={theme.type} style={!data.tags.length ? {} : { height: '30vh', overflowY: 'scroll' }} id="scrollableDiv">
                                                    {!data.tags.length ? <small className='pl-5 text-secondary'>No Tags Found!</small>
                                                        : <List dense>
                                                            {
                                                                data.tags.length && data.tags.map((filter: T, i: number) => {
                                                                    const labelId = `checkbox-list-secondary-label-${i}`;
                                                                    return (
                                                                        <ListItem key={i} className='pl-5' button onClick={() => this.toggleFilter(filter._id, 'tags')}>
                                                                            <ListItemText id={labelId} primary={filter.name} />
                                                                            <ListItemSecondaryAction>
                                                                                <Checkbox
                                                                                    edge="end"
                                                                                    color="primary"
                                                                                    onChange={() => this.toggleFilter(filter._id, 'tags')}
                                                                                    // checked={this.state.filters.indexOf(filter) !== -1}
                                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                                />
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    );
                                                                })
                                                            }

                                                        </List>}
                                                </FilterContainer>
                                                }
                                            </List>
                                            :
                                            <FilterContainer mode={theme.type} style={!filters.length ? {} : { height: '30vh', overflowY: 'scroll' }} id="scrollableDiv">
                                                <List dense>
                                                    {
                                                        !this.props?.selectOne ?
                                                            filters?.map((filter: T, i: number) => {
                                                                const labelId = `checkbox-list-secondary-label-${i}`;
                                                                return (
                                                                    <ListItem key={i} button onClick={() => this.toggleFilter(filter, 'filter')}>
                                                                        <ListItemText id={labelId} primary={filter.name} />
                                                                        <ListItemSecondaryAction>
                                                                            <Checkbox
                                                                                edge="end"
                                                                                color="primary"
                                                                                onChange={() => this.toggleFilter(filter, 'filter')}
                                                                                checked={this.state.filters.indexOf(filter) !== -1}
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                            />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                );
                                                            }) :
                                                            filters?.map((filter: T, i: number) => {
                                                                const labelId = `checkbox-list-secondary-label-${i}`;
                                                                return (
                                                                    <ListItem key={i} button onClick={() => this.toggleFilter(filter, 'filter', true)}>
                                                                        <ListItemText id={labelId} primary={filter.name} />
                                                                        <ListItemSecondaryAction>
                                                                            <Checkbox
                                                                                edge="end"
                                                                                color="primary"
                                                                                onChange={() => this.toggleFilter(filter, 'filter')}
                                                                                checked={!this.state.filter && i === 0 ? true : this.state.filter === filter}
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                            />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                );
                                                            })
                                                    }
                                                </List>
                                            </FilterContainer>}
                                    </Paper>
                                </div>}
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment >)
    }
}

export default withTheme(Filters)

