import loadable from "@loadable/component";
import { ComponentType } from "react";
import { Fallback } from '../Components';
import { MODULES, PERMISSIONS } from "../Configs/Constants";
import BillingListView from "../Views/Dashboard/Billings/BillingList.View";
import EditBilling from "../Views/Dashboard/Billings/EditBilling.View";
import GeographicDataConsumptionReport from "../Views/Dashboard/Reports/GeographicDataConsumptionReport.View";
import DeviceUsageReport from "../Views/Dashboard/Reports/DeviceUsageReport.View";

const DashboardView = loadable(() => import("../Views/Dashboard/Dashboard.View"), {
    fallback: <Fallback />
});

//PROFILE
const EditProfileView = loadable(() => import("../Views/Dashboard/Profile/EditProfile.View"), {
    fallback: <Fallback />
});


const EditRole = loadable(() => import("../Views/Dashboard/Roles/EditRole.View"), {
    fallback: <Fallback />
});

const RoleListView = loadable(() => import("../Views/Dashboard/Roles/RoleList.View"), {
    fallback: <Fallback />
});


//PRICES
const EditPriceView = loadable(() => import("../Views/Dashboard/Prices/Edit.View or"), {
    fallback: <Fallback />
});

const PriceListView = loadable(() => import("../Views/Dashboard/Prices/List.View"), {
    fallback: <Fallback />
});


//SIMS
const SimListView = loadable(() => import("../Views/Dashboard/Sims/SimList.View"), {
    fallback: <Fallback />
});

const EditSimView = loadable(() => import("../Views/Dashboard/Sims/EditSim.View"), {
    fallback: <Fallback />
});

// Users


const UserManagement = loadable(() => import("../Views/Dashboard/UserManagement/UserManagement.View"), {
    fallback: <Fallback />
});

const CompanyManagement = loadable(() => import("../Views/Dashboard/CompanyManagement/CompanyManagement.View"), {
    fallback: <Fallback />
});

//Groups
const GroupsListView = loadable(() => import("../Views/Dashboard/Groups/GroupList.View"), {
    fallback: <Fallback />
});
const GroupsDetailsView = loadable(() => import("../Views/Dashboard/Groups/GroupDetails.View"), {
    fallback: <Fallback />
});

//EMPLOYEE 
const EmployeeListView = loadable(() => import("../Views/Dashboard/Employees/EmployeeList.View"), {
    fallback: <Fallback />
});

const EditEmployeeView = loadable(() => import("../Views/Dashboard/Employees/EditEmployee.View"), {
    fallback: <Fallback />
});

const BillingsListView = loadable(() => import("../Views/Dashboard/Billings/BillingList.View"), {
    fallback: <Fallback />
})
//EMPLOYEE 
// const ResellerListView = loadable(() => import("../Views/Dashboard/Resellers/ResellerList.View"), {
//     fallback: <Fallback />
// });

// const EditResellerView = loadable(() => import("../Views/Dashboard/Resellers/EditReseller.View"), {
//     fallback: <Fallback />
// });

//COMPANY 
const CompanyListView = loadable(() => import("../Views/Dashboard/Companies/CompanyList.View"), {
    fallback: <Fallback />
});

const EditCompanyView = loadable(() => import("../Views/Dashboard/Companies/EditCompany.View"), {
    fallback: <Fallback />
});

//BRANCH
const BranchListView = loadable(() => import("../Views/Dashboard/Branches/BranchList.View"), {
    fallback: <Fallback />
});

const EditBranchView = loadable(() => import("../Views/Dashboard/Branches/EditBranch.View"), {
    fallback: <Fallback />
});

//USER
const UserListView = loadable(() => import("../Views/Dashboard/Users/UserList.View"), {
    fallback: <Fallback />
});

const EditUserView = loadable(() => import("../Views/Dashboard/Users/EditUser.View"), {
    fallback: <Fallback />
});

//RECHARGE
const RechargeView = loadable(() => import("../Views/Dashboard/Payments/Recharge.view"), {
    fallback: <Fallback />
});

const PaymentListView = loadable(() => import("../Views/Dashboard/Payments/PaymentList.View"), {
    fallback: <Fallback />
});

const UsageView = loadable(() => import("../Views/Dashboard/Payments/Usage.View"), {
    fallback: <Fallback />
});

const AutomationListView = loadable(() => import("../Views/Dashboard/Automation/AutomationList.View"), {
    fallback: <Fallback />
});

const EditAutomation = loadable(() => import("../Views/Dashboard/Automation/EditAutomation.View"), {
    fallback: <Fallback />
});

const CustomerUsageReport = loadable(() => import("../Views/Dashboard/Reports/CustomerUsageReport.View"), {
    fallback: <Fallback />
});

const ProviderDataUsageReport = loadable(() => import("../Views/Dashboard/Reports/ProviderDataUsageReport.View"), {
    fallback: <Fallback />
});

const DataUsageTrendReport = loadable(() => import("../Views/Dashboard/Reports/DataUsageTrendReport.View"), {
    fallback: <Fallback />
});

const CustomerProviderPreferenceReport = loadable(() => import("../Views/Dashboard/Reports/CustomerProviderPreferenceReport.View"), {
    fallback: <Fallback />
});

const CostAnalysisReport = loadable(() => import("../Views/Dashboard/Reports/CostAnalysisReport.View"), {
    fallback: <Fallback />
});

const HighUsageCustomerReport = loadable(() => import("../Views/Dashboard/Reports/HighUsageCustomerReport.View"), {
    fallback: <Fallback />
});

const ProviderPerformenceReport = loadable(() => import("../Views/Dashboard/Reports/ProviderPerformanceReport.View"), {
    fallback: <Fallback />
});

const ProviderCostAnalysisReport = loadable(() => import("../Views/Dashboard/Reports/ProviderCostAnalysisReport.View"), {
    fallback: <Fallback />
});

const CustomerDetailReport = loadable(() => import("../Views/Dashboard/Reports/CustomerDetailReport.View"), {
    fallback: <Fallback />
});

const CountrywiseTotalConsumptionReport = loadable(() => import("../Views/Dashboard/Reports/CountrywiseTotalConsumptionReport.View"), {
    fallback: <Fallback />
});

const ProviderTotalConsumptionReport = loadable(() => import("../Views/Dashboard/Reports/ProviderTotalConsumptionReport.View"), {
    fallback: <Fallback />
});

export interface RouteInterface {
    path: string;
    exact?: boolean,
    name: string;
    module?: string;
    permissions?: Array<string>;
    component: ComponentType
}


export const routes: Array<RouteInterface> = [
    { path: '/dashboard', exact: true, name: 'Login', component: DashboardView },
    { path: '/profile', exact: true, name: 'Profile', component: EditProfileView },

    // SIMS ROUTES
    { path: '/sims', module: MODULES.SIMS_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Sims', component: SimListView },
    //{ path: '/sims/new', module: MODULES.SIMS_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Sim', component: EditSimView },
    { path: '/sims/:id', module: MODULES.SIMS_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Sim Details', component: EditSimView },

    // User Management
    { path: '/user-management', exact: true, name: 'User Management', component: UserManagement },

    // SIMS ROUTES
    { path: '/groups', module: MODULES.GROUP_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Groups', component: GroupsListView },
    { path: '/groups/:id', module: MODULES.GROUP_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Groups Details', component: GroupsDetailsView },

    // ROLE ROUTES
    { path: '/roles/new', module: MODULES.ROLE_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Role', component: EditRole },
    { path: '/roles', module: MODULES.ROLE_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Roles', component: RoleListView },
    { path: '/roles/:id', module: MODULES.ROLE_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Role Details', component: EditRole },

    // PRICES ROUTES
    { path: '/prices', module: MODULES.PRICING_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Price Books', component: PriceListView },
    { path: '/prices/new', module: MODULES.PRICING_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Pricebook', component: EditPriceView },
    { path: '/prices/:id', module: MODULES.PRICING_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Pricing Condition', component: EditPriceView },


    //RESELLER ROUTES
    // { path: '/resellers', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Resellers', component: ResellerListView },
    // { path: '/resellers/new', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Reseller', component: EditResellerView },
    // { path: '/resellers/:id', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Details', component: EditResellerView },
    // { path: '/resellers/:reseller/employees', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Employees', component: EmployeeListView },
    // { path: '/resellers/:reseller/employees/new', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Add Reseller Employee', component: EditEmployeeView },
    // { path: '/resellers/:reseller/employees/:id', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Edit Reseller Employee', component: EditEmployeeView },
    // { path: '/resellers/:reseller/sims', module: MODULES.SIMS_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Sims', component: SimListView },
    // { path: '/resellers/:reseller/sims/:id', module: MODULES.SIMS_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Sims', component: EditSimView },
    // { path: '/resellers/:reseller/roles', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Roles', component: RoleListView },
    // { path: '/resellers/:reseller/roles/new', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'New Role', component: EditRoleView },
    // { path: '/resellers/:reseller/roles/:id', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Edit Roles', component: EditRoleView },
    // { path: '/resellers/:reseller/companies', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Companies', component: CompanyListView },
    // { path: '/resellers/:reseller/companies/new', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Companies', component: EditCompanyView },
    // { path: '/resellers/:reseller/companies/:id', module: MODULES.RESELLER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Companies', component: EditCompanyView },


    //COMPANY ROUTES
    { path: '/companies', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Companies', component: CompanyListView },
    { path: '/companies/new', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Company', component: EditCompanyView },
    { path: '/companies/:id', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Company', component: CompanyManagement },
    { path: '/companies/:company/sims', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Sims', component: SimListView },
    { path: '/companies/:company/sims/:id', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Reseller Sim', component: EditSimView },
    { path: '/companies/:company/branches', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Branches', component: BranchListView },
    { path: '/companies/:company/branches/new', module: MODULES.BRANCH_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Branch', component: EditBranchView },
    { path: '/companies/:company/branches/:id', module: MODULES.BRANCH_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Edit Branch', component: EditBranchView },
    { path: '/companies/:company/employees', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE, PERMISSIONS.READ], exact: true, name: 'Employees', component: EmployeeListView },
    { path: '/companies/:company/employees/new', module: MODULES.USER_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Employee', component: EditEmployeeView },
    { path: '/companies/:company/employees/:id', module: MODULES.USER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Employee Details', component: EditEmployeeView },
    { path: '/companies/:company/usage', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Usage', component: BillingListView },
    { path: '/companies/:company/billings', module: MODULES.COMPANY_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Billings', component: PaymentListView },

    // EMPLOYEE ROUTES
    { path: '/employees', module: MODULES.EMPLOYEE_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Employees', component: EmployeeListView },
    { path: '/employees/new', module: MODULES.EMPLOYEE_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Employee', component: EditEmployeeView },
    { path: '/employees/:id', module: MODULES.EMPLOYEE_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Employee Details', component: EditEmployeeView },

    //BRANCH ROUTES
    { path: '/branches', module: MODULES.BRANCH_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Branches ', component: BranchListView },
    { path: '/branches/new', module: MODULES.BRANCH_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New Branch', component: EditBranchView },
    { path: '/branches/:id', module: MODULES.BRANCH_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Branch Details', component: EditBranchView },

    //USER ROUTES
    { path: '/users', module: MODULES.USER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Users', component: UserListView },
    { path: '/users/new', module: MODULES.USER_MANAGEMENT, permissions: [PERMISSIONS.READ_WRITE], exact: true, name: 'New User', component: EditUserView },
    { path: '/users/:id', module: MODULES.USER_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'User Details', component: EditUserView },

    //RECHARGE ROUTES
    { path: '/recharge', module: MODULES.PRICING_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Recharge', component: PaymentListView },
    { path: '/recharge/new', module: MODULES.PRICING_MANAGEMENT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'New Recharge', component: RechargeView },
    { path: '/credit-usage', exact: true, name: 'Credit Usage', component: UsageView },

    //AUTOMATION ROUTES
    { path: '/automation', module: MODULES.AUTOMATION, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Automation', component: AutomationListView },
    { path: '/automation/new', module: MODULES.AUTOMATION, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'New Rule', component: EditAutomation },
    { path: '/automation/:id', module: MODULES.AUTOMATION, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Edit Rule', component: EditAutomation },

    //BILLING ROUTES
    { path: '/billing', module: MODULES.BILLINGS, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Billing', component: BillingListView },
    { path: '/billing/new', module: MODULES.BILLINGS, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'New Bill', component: EditBilling },
    { path: '/billing/:id', module: MODULES.BILLINGS, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Edit Bill', component: EditBilling },

    //USAGE ROUTES
    { path: '/usage', module: MODULES.USAGE, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Usage', component: BillingListView },
    // { path: '/billing/new', module: MODULES.BILLINGS, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'New Bill', component: EditBilling },
    // { path: '/billing/:id', module: MODULES.BILLINGS, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Edit Bill', component: EditBilling },

    //REPORT ROUTES
    { path: '/customer-usage-report', module: MODULES.CUSTOMER_USAGE_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Customer Usage Report', component: CustomerUsageReport },
    { path: '/provider-data-usage-report', module: MODULES.PROVIDER_DATA_USAGE_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Provider Data Usage Report', component: ProviderDataUsageReport },
    { path: '/geographic-data-consumption-report', module: MODULES.GEOGRAPHIC_DATA_CONSUMPTION_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Provider Data Usage Report', component: GeographicDataConsumptionReport },
    { path: '/data-usage-trends-report', module: MODULES.GEOGRAPHIC_DATA_CONSUMPTION_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Provider Data Usage Report', component: DataUsageTrendReport },
    { path: '/device-usage-report', module: MODULES.DEVICE_USAGE_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Device Usage Report', component: DeviceUsageReport },
    { path: '/customer-provider-preference-report', module: MODULES.CUSTOMER_PROVIDER_PREFERENCE_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Customer Provider Preference Report', component: CustomerProviderPreferenceReport },
    { path: '/cost-analysis-report', module: MODULES.COST_ANALYSIS_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Cost Analysis Report', component: CostAnalysisReport },
    { path: '/high-usage-customer-report', module: MODULES.HIGH_USAGE_CUSTOMER_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'High Usage Customer Report', component: HighUsageCustomerReport },
    { path: '/provider-performance-report', module: MODULES.PROVIDER_PERFORMANCE_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Provider Performance Report', component: ProviderPerformenceReport },
    { path: '/provider-cost-analysis-report', module: MODULES.PROVIDER_COST_ANALYSIS_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Provider Cost Analysis Report', component: ProviderCostAnalysisReport },
    { path: '/customer-detail-report', module: MODULES.CUSTOMER_DETAIL_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Customer Detail Report', component: CustomerDetailReport },
    { path: '/countrywise-total-consumption-report', module: MODULES.COUNTRYWISE_TOTAL_CONSUMPTION_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Countrywise Total Consumption Report', component: CountrywiseTotalConsumptionReport },
    { path: '/provider-total-consumption-report', module: MODULES.PROVIDER_TOTAL_CONSUMPTION_REPORT, permissions: [PERMISSIONS.READ, PERMISSIONS.READ_WRITE], exact: true, name: 'Provider Total Consumption Report', component: ProviderTotalConsumptionReport }
];