export { DashboardSideMenuState } from './DashboardSideMenu.States';

export {
    LoginState,
    ResetState,
    ForgetState,
    ProfileState
} from './Auth.States';

export {
    PlanListState,
    EditPlanState,
    PlanViewState
} from './Plan.States';

export {
    PriceListState,
    EditPriceState,
    NewPriceState,
    PriceViewState
} from './Price.State';

export {
    EmployeeListState,
    EmployeeDetailState,
    EditEmployeeState
} from './Employee.States';

export {
    SimListState,
    SimDetailsState,
    EditSimState
} from './Sim.States';

export {
    RoleListState,
    RoleViewState,
    RoleDetailsState
} from './Role.States';

export {
    CompanyListState,
    CompanyDetailState,
    EditCompanyState
} from './Company.States';

export {
    ResellerListState,
    ResellerDetailState,
    EditResellerState
} from './Reseller.States';

export {
    UserListState,
    UserDetailState,
    EditUserState
} from './User.States';

export {
    BranchListState,
    BranchDetailState,
    EditBranchState
} from './Branch.States';

export {
    GroupListState,
    GroupDetailsState
} from './Group.States'

export {
    RechargeState,
    PaymentListState
} from './Recharge.States'

export {
    CreditUsageListState,
} from './DataUsage.States'

export {
    ListAutomationRuleState,
    EditAutomationState
} from './Automation.States'

export class DashboardLayoutState {
    public isOpen: boolean = false;
    public level1: boolean = false;
    public level2: boolean = false;
    public type: number = 0;
    public ev: string = 'forward';
    public megaMenu: boolean = false;
    public anchorEl: any = null
}

export { BillingListState } from './Billing.States'

// REPORT STATES
export {
    CustomerUsageReportState
} from './CustomerUsageReport.States'

export {
    CustomerDetailReportState
} from './CustomerDetailReport.States'

export {
    ProviderDataUsageReportState
} from './ProviderDataUsageReport.States'

export {
    GeographicDataConsumptionReportState
} from './GeographicDataConsumptionReport.States'

export {
    DataUsageTrendReportState
} from './DataUsageTrendReport.States'

export {
    DeviceUsageReportState
} from './DeviceUsageReport.States'

export {
    CustomerProviderPreferenceReportState
} from './CustomerProviderPreferenceReport.States'

export {
    CostAnalysisReportState
} from './CostAnalysisReport.States'

export {
    HighUsageCustomerReportState
} from './HighUsageCustomerReport.States'

export {
    ProviderPerformanceReportState
} from './ProviderPerformanceReport.States'

export {
    ProviderCostAnalysisReportState
} from './ProviderCostAnalysisReport.States'

export {
    CountrywiseTotalConsumptionReportState
} from './CountrywiseTotalConsumptionReport.States'

export {
    ProviderTotalConsumptionReportState
} from './ProviderTotalConsumptionReport.States'
