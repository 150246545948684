import { Component } from 'react';
import ReeValidate from 'ree-validate';

class Mixin extends Component {
    validator;
    user;
    timer;
    constructor(props) {
        if(props.title)
            document.title = 'OnDigo|' + props.title;
        else
            document.title = 'OnDigo';

        super(props);
        this.user = props.user || {};
    }

    initValidation(param, attributes = {}){
       this.validator = new ReeValidate.Validator(param);
       if(attributes){
            this.validator.localize({
            en: {
                attributes: attributes
            }
          });
       }
    }
}

export default Mixin;