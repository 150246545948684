
import BaseService from './BaseService';
import { Http } from '../Http';
import { AutomationRuleModel, Options, Response, ResponseList, SimDataTrafficModel, SimModel } from '../Redux/Models';

export default class SimService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<SimModel>> {
        console.log(params)
        let url: string = '/sims';
        if (options?.root) {
            url = options.root + url;
        }
        SimService.initCancelToken();
        if (options?.source) {
            SimService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: SimService.source?.token })
                .then(res => {
                    let data = new ResponseList<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getListByCompany(id: string, params: Object = {}, options: Options = {}): Promise<ResponseList<SimModel>> {
        SimService.initCancelToken();

        return new Promise((resolve, reject) => {
            Http.get('/companies/' + id + '/sims', { params, cancelToken: SimService.source?.token }).then(resp => {
                let data = new ResponseList<SimModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                reject(err)
            })
        })

    }

    public static getAutomationRulesById(id: string, params: Object = {}, options: Options = {}): Promise<Response<AutomationRuleModel>> {
        let url: string = '/sims/' + id + '/automation-rules';
        if (options?.root) {
            url = options.root + url;
        }
        SimService.initCancelToken();
        if (options?.source) {
            SimService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: SimService.source?.token })
                .then(res => {
                    let data = new Response<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static deleteAutomationRuleById(simId: string, id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url: string = '/sims/' + simId + '/automation-action/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.delete(url, { params, cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: Object = {}, options: Options = {}): Promise<Response<SimModel>> {
        let url: string = '/sims';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post(url, params)
                .then(res => {
                    let data = new Response<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static assignAutomationRules(params: Object = {}, options: Options = {}): Promise<Response<SimModel>> {
        let url: string = '/automation/action';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post(url, params)
                .then(res => {
                    let data = new Response<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static update(id: string, params: Object = {}, options: Options = {}): Promise<Response<SimModel>> {
        this.initCancelToken();
        let url: string = '/sim/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static delete(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url: string = '/sims/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.delete(url, { params, cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateStatus(id: string, params: Object = {}, options: Options = {}): Promise<Response<SimModel>> {
        this.initCancelToken();
        let url: string = `/sims/${id}/status`
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getById(id: String, params = {}, options: Options = {}): Promise<Response<SimModel>> {
        this.initCancelToken();
        let url: string = '/sims/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: this.source?.token }).then(res => {
                let data = new Response<SimModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getDataTrafficBySim(params: Object = {}, options: Options = {}): Promise<ResponseList<SimDataTrafficModel>> {
        let url: string = 'sim/traffics/';
        if (options?.root) {
            url = 'sim/' + options.root + '/traffics/';
        }
        SimService.initCancelToken();
        if (options?.source) {
            SimService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: SimService.source?.token }).then(res => {
                let data = new ResponseList<SimDataTrafficModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static activate(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url: string = `/sims/activate/${id}`;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateStateById(id: string, params: Object = {}, options: Options = {}): Promise<Response<SimModel>> {
        this.initCancelToken();
        let url: string = '/sims/' + id + '/state';

        if (options?.root) {
            url = options.root + url;
        }

        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
    public static unblock(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url: string = `/sims/unblock/${id}`;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, { params, cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static disconnect(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url: string = `/sims/disconnect/${id}`;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, { params, cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static assign(params: Object = {}, options: Options = {}): Promise<Response<SimModel>> {
        let url: string = '/sims/assign';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch(url, params)
                .then(res => {
                    let data = new Response<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static saveTags(id: string, data: Object = {}): Promise<Response<any>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/sims/' + id + '/tags', data)
                .then(res => {
                    let data = new Response<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static changeGroup(id: string, data: object = {}) {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch('/sims/' + id + '/group', data)
                .then(res => {
                    let data = new Response<any>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static sendSMStoSim(id: string, params: object = {}) {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/sims/' + id + '/send-sms', params)
                .then(res => {
                    let data = new Response<any>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });

    }

    public static sendSMStoSims(data: object = {}) {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/sims/send-sms', data)
                .then(res => {
                    let data = new Response<any>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });

    }
}