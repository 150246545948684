import { createGlobalStyle, css } from "styled-components";
import { Props, PropsGlobalStyle } from "./Redux/Models";
const GlobalStyle: any = createGlobalStyle`
  ${(props: PropsGlobalStyle) => {
        return css`
      *:focus {
        outline: none !important;
      }

      html, body {
        margin: 0;
        padding: 0;
      }

    body {
      background-color: ${props.theme.colors.bodyBgColor};
      color:  ${props.theme.colors.textColor};
      font-size: 14px;
      font-family: 'Be Vietnam Pro', sans-serif;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-bottom: 0;
    }

    h1 {
        font-size: 48px;
    }

    h2 {
        font-size: 40px;
    }

    h3 {
        font-size: 32px;
    }

    h4 {
        font-size: 24px;
    }

    h5 {
        font-size: 16px;
    }

    h6 {
        font-size: 12px;
    }

    a {
        text-decoration: none !important;
    }

    .small-text {
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

  .absolute-center {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      height: 100%;
      width: 100%;
  }

  .with-underline{
    position: relative;
    display: inline-block;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        background-color: ${props.theme.colors.orange};
        bottom: 0;
        width: 100%;
        height: 5px;
        border-radius: 20px;
        z-index: -1;
    }
  }

  .auth-part {
    position: relative;

    .patterns {
        position: absolute;
        height: 45vh;
        object-fit: contain;
        z-index: -1;
        max-height: 300px;
        width: 100%;
    }

    .patterns.dt,
    .patterns.mt {
        top: 0;
        right: 0;
        object-position: top right;
    }

    .patterns.db,
    .patterns.mb {
        bottom: 0;
        left: 0;
        object-position: bottom left;
    }

    .patterns.mt,
    .patterns.mb {
        display: none;
    }

    min-height: 100vh;
    padding: 15px 0;

    .form-holder {
        min-height: 350px;
        background-color: ${props.theme.colors.white};
        display: flex;
        align-items: stretch;
        border-radius: 5px;

        .left-part {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: ${props.theme.colors.purple};
            width: 50%;
            padding: 40px 60px;
            position: relative;
            min-height: 400px;

            h1 {
                font-size: 80px;
            }
        }

        .right-part {
            width: 50%;
            padding: 40px 55px;
            background-color: ${props.theme.colors.white};

            .btn-holder {
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    color: ${props.theme.colors.darkGrey}; !important;
                }
            }
        }
    }
}

    .dashboard-main-content {
        height: 100vh;
        padding-left: 300px;

        .dashboard-content {
            padding: 25px 50px;

            display: flex;
            flex-direction: column;
            min-height: 100vh !important;
        }
    }

    .stylish-scroller::-webkit-scrollbar{
		width: 5px !important;
		background-color: ${props.theme.colors.textColor} !important;
    }

    .stylish-scroller::-webkit-scrollbar-thumb{
        border-radius: 10px !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
        background-color: ${props.theme.colors.primaryColor} !important;
    }

    body::-webkit-scrollbar{
		width: 6px !important;
		background-color: ${props.theme.colors.bodyBgColor} !important;
    }

    body::-webkit-scrollbar-thumb{
        border-radius: 10px !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
        background-color: ${props.theme.colors.primaryColor} !important;
    }

    .action-icon {
        color: ${props.theme.colors.primaryColor}; !important;
    }

    @media (max-width: 1023px) {
    .auth-part {
        .form-holder {
            .right-part {
                padding: 15px;
            }
        }
    }
}

@media (max-width: 767px) {
    .auth-part {
        .form-holder {
            flex-direction: column;
            margin: 60vw 0;

            .left-part,
            .right-part {
                width: 100%;
            }

            .left-part {
                order: 2;

                h1 {
                    font-size: 50px;
                }
            }
        }

        .patterns {
            height: unset;
            max-height: unset;
            object-fit: unset;
            object-position: unset;
        }

        .patterns.mt,
        .patterns.mb {
            display: block;
        }

        .patterns.dt,
        .patterns.db {
            display: none;
        }
    }

    .inside-main-content,
    .inside-footer {
        width: 100vw;
    }
}

@media (max-width: 600px) {
    .auth-part {
        .form-holder {
            .right-part {
                .btn-holder {
                    flex-direction: column;
                    align-items: flex-start;

                    a {
                        margin-bottom: 15px;
                    }

                    .btn {
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .auth-part {
        .form-holder {
            .left-part {
                padding: 15px;
            }
        }
    }

    .inside-footer {
        text-align: center;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media (min-width: 1024px) {
    .auth-part {
        .form-holder {
            .right-part {
                .alert-holder {
                    top: -65px;
                    position: relative;
                }
            }
        }
    }
}
  `}};
`;

export default GlobalStyle;