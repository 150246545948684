import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'

export class FBIcon extends Component<Props, States> {
    static defaultProps = {
        width: 24,
        height: 24
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.type === 'light' ? theme.colors.purple : theme.colors.orange;
        return (
            <SVGContainer {...restProps}>
                <svg width={width} height={height} style={{ color: color }} viewBox="0 0 21 21" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4155 20.1306C11.0866 20.1633 10.753 20.18 10.4155 20.18C10.078 20.18 9.74443 20.1633 9.41553 20.1306V12.18H8.41553C7.86324 12.18 7.41553 11.7323 7.41553 11.18C7.41553 10.6277 7.86324 10.18 8.41553 10.18H9.41553V8.17999C9.41553 6.52314 10.7587 5.17999 12.4155 5.17999H13.4155C13.9678 5.17999 14.4155 5.62771 14.4155 6.17999C14.4155 6.73228 13.9678 7.17999 13.4155 7.17999H12.4155C11.8632 7.17999 11.4155 7.62771 11.4155 8.17999V10.18H13.4155C13.9678 10.18 14.4155 10.6277 14.4155 11.18C14.4155 11.7323 13.9678 12.18 13.4155 12.18H11.4155V20.1306Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4155 18.18C14.8338 18.18 18.4155 14.5983 18.4155 10.18C18.4155 5.76171 14.8338 2.17999 10.4155 2.17999C5.99725 2.17999 2.41553 5.76171 2.41553 10.18C2.41553 14.5983 5.99725 18.18 10.4155 18.18ZM10.4155 20.18C15.9384 20.18 20.4155 15.7028 20.4155 10.18C20.4155 4.65715 15.9384 0.179993 10.4155 0.179993C4.89268 0.179993 0.415527 4.65715 0.415527 10.18C0.415527 15.7028 4.89268 20.18 10.4155 20.18Z" fill="currentColor" />
                </svg>
            </SVGContainer>
        );
    }
}

export default withTheme(FBIcon);



