import { SvgIconComponent } from "@material-ui/icons";
import { paymentModel } from "./Recharge.models";
import { PermissionModel, RoleModel } from "./Role.Models";
import { UserModel } from "./User.Models";

export class InitialAuthStateModel {
    [key: string]: any;
    public token:string;
    public isAuthenticated: boolean;
    public isExpired: boolean;
    public user: UserModel
}

export class InitialConfigStateModel {
    [key: string]: any;
    public isToggled:boolean;
    public roles:Array<RoleModel>;
    public permissions:Array<PermissionModel>;
}

export abstract class BaseStateModel {
    public errors = {
        has:(e:string): boolean => {
            return false;
        },
        first: (e:string): string => {
            return '';
        }
    };
}

export class DataTableHeaderModel {
    public label:string;
    public key:string;
    public isSort:boolean;
    public style?:{
        [key: string]: any;
    };
    public render?:Function;
}

type Color = 'inherit' | 'primary' | 'secondary' | 'default';
type Direction = 'asc' | 'desc';

export class DataTableActionModel {
    public icon:SvgIconComponent;
    public key:string;
    public color?:Color;
    public tooltip?:string;
    public match?:any;
}

class Status{
    public key:string;
    public label:string;
    public width?:string;
}

export class DataTableModel {
    [key: string]: any;
    public request:Function;
    public status?:Status;
    public actionWidth?:string;
    public actions?:Array<DataTableActionModel>;
    public headers:Array<DataTableHeaderModel>;
    public pagination?:boolean;
    public limit?:number;
    public active?:string;
    public direction?:Direction;
}

export class DataCardModel {
    [key: string]: any;
    public status?:Status;
    public limit?:number;
    public page?:number;
    public total?:number;
    public active?:string;
    public direction?:Direction;
    public q?:string;
}

export class StateModel {
    public Auth:InitialAuthStateModel;
    public Config:InitialConfigStateModel;
}

export abstract class BaseModel {
    [key: string]: any;
    public createdAt?:string;
    public updatedAt?:string;
    public deletedAt?:string;
}