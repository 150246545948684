
import BaseService from './BaseService';
import { Http, RequestConfig } from '../Http';
import { Login, Logout, LoadUser, LoadRoles, LoadPermissions } from '../Redux/Actions';
import { Response, ResponseList, RoleModel, PermissionModel, UserModel, ModuleModel, ModulePermissionModel, Options } from '../Redux/Models';

export default class AuthService extends BaseService {

    public static login(params: Object) {
        return (dispatch: Function) => new Promise((resolve, reject) => {
            Http.post('/auth/login', params).then(res => {
                if (res?.data.status === 200) {
                    dispatch(Login(res?.data.data));
                }
                return resolve(res?.data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getMe() {
        return (dispatch: Function) => new Promise((resolve, reject) => {
            Http.get('/auth/me').then(res => {
                if (res?.data.status === 200) {
                    dispatch(LoadUser(res?.data.data.user));
                    if (res?.data.data.roles) {
                        console.log(res?.data.data.roles)
                        dispatch(LoadRoles(res?.data.data.roles));
                    }
                    if (res?.data.data.permissions) {
                        dispatch(LoadPermissions(res?.data.data.permissions));
                    }
                }
                return resolve(res?.data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static logout() {
        return (dispatch: Function) => new Promise((resolve, reject) => {
            Http.post('/auth/logout').then(res => {
                if (res?.data.status === 200) {
                    dispatch(Logout());
                }
                return resolve(res?.data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static requestPassword(params: Object) {
        return new Promise((resolve, reject) => {
            Http.post('/auth/forgot-password', params).then(res => {
                let data = new Response<null>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateProfile(params: Object) {
        return (dispatch: Function) => new Promise((resolve, reject) => {
            Http.put('/auth/update-profile', params).then(res => {
                if (res?.data.status === 200) {
                    dispatch(LoadUser(res?.data.data));
                }
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updatePassword(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.put('/auth/update-password', params).then(res => {
                let data = new Response<null>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    console.error(err);
                    return reject(err);
                });
        });
    }

    public static uploadImage(params: FormData, options: RequestConfig = {}) {
        return (dispatch: Function) => new Promise((resolve, reject) => {
            Http.post('/auth/profile-image', params, options).then(res => {
                if (res?.data.status === 200) {
                    dispatch(LoadUser(res?.data.data));
                }
                let data = new Response<null>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    console.error(err);
                    return reject(err);
                });
        });
    }


    public static resetPassword(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/reset-password', params).then(res => {
                let data = new Response<null>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getRoles(params: Object = {}, options: Options = {}): Promise<ResponseList<RoleModel>> {
        let url: string = '/roles';
        if (options?.root) {
            url = options.root + url;
        }
        AuthService.initCancelToken();
        if (options?.source) {
            AuthService.source = options?.source
        }

        return new Promise((resolve, reject) => {
            console.log(url);
            Http.get(url, { params, cancelToken: AuthService.source?.token }).then(res => {
                let data = new ResponseList<RoleModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static deleteRole(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        let url: string = '/roles/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.delete(url, { params }).then(res => {
                let data = new Response<null>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getModules(params: Object = {}): Promise<Response<Array<ModuleModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/modules', { params }).then(res => {
                let data = new Response<Array<ModuleModel>>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getRolePermissions(id: string, params: Object = {}, options: Options = {}): Promise<Response<ModulePermissionModel[]>> {
        let url: string = '/roles/' + id + '/permissions';
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params }).then(res => {
                let data = new Response<ModulePermissionModel[]>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static createOrUpdateRolePermissions(id: string, params: Object = {}): Promise<Response<ModulePermissionModel>> {
        return new Promise((resolve, reject) => {
            Http.post('/roles/' + id + '/permissions', params).then(res => {
                let data = new Response<ModulePermissionModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static createRole(params: Object, options: Options = {}): Promise<Response<RoleModel>> {
        let url: string = '/roles';
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.post(url, params).then(res => {
                let data = new Response<RoleModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateRole(id: string, params: Object = {}, options: Options = {}): Promise<Response<RoleModel>> {
        let url: string = '/roles/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params).then(res => {
                let data = new Response<RoleModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getRoleById(id: string, params: Object = {}, options: Options = {}): Promise<Response<RoleModel>> {
        let url: string = '/roles/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params }).then(res => {
                let data = new Response<RoleModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getPermissions(params: Object = {}): Promise<Response<Array<PermissionModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/permissions', { params }).then(res => {
                let data = new Response<Array<PermissionModel>>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getMFASecret(): Promise<Response<any>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/mfa/secret').then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static enableMFA(params: Object) {
        return (dispatch: Function) => new Promise((resolve, reject) => {
            Http.post('/auth/mfa/enable', params).then(res => {
                if (res?.data.status === 200) {
                    dispatch(LoadUser(res?.data.data));
                }
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static disableMFA() {
        return (dispatch: Function) => new Promise((resolve, reject) => {
            Http.post('/auth/mfa/disable').then(res => {
                if (res?.data.status === 200) {
                    dispatch(LoadUser(res?.data.data));
                }
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}