import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'

export class SunnyIcon extends Component<Props, States> {
    static defaultProps = {
        width: 24,
        height: 24
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.colors.textColor;
        
        return (
            <SVGContainer {...restProps}>
                <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14.9999C13.6569 14.9999 15 13.6568 15 11.9999C15 10.3431 13.6569 8.99994 12 8.99994C10.3431 8.99994 9 10.3431 9 11.9999C9 13.6568 10.3431 14.9999 12 14.9999ZM12 16.9999C14.7614 16.9999 17 14.7614 17 11.9999C17 9.23852 14.7614 6.99994 12 6.99994C9.23858 6.99994 7 9.23852 7 11.9999C7 14.7614 9.23858 16.9999 12 16.9999Z" fill="currentColor" />
                    <path d="M11 2.99994C11 2.44765 11.4477 1.99994 12 1.99994C12.5523 1.99994 13 2.44765 13 2.99994V3.99994C13 4.55222 12.5523 4.99994 12 4.99994C11.4477 4.99994 11 4.55222 11 3.99994V2.99994Z" fill="currentColor" />
                    <path d="M5.636 7.0508C6.02653 7.44132 6.65969 7.44132 7.05022 7.0508C7.44074 6.66027 7.44074 6.02711 7.05022 5.63658L6.34311 4.92948C5.95259 4.53895 5.31942 4.53895 4.9289 4.92948C4.53837 5.32 4.53837 5.95316 4.9289 6.34369L5.636 7.0508Z" fill="currentColor" />
                    <path d="M19.0711 4.9297C18.6806 4.53918 18.0474 4.53918 17.6569 4.9297L16.9498 5.63681C16.5593 6.02733 16.5593 6.6605 16.9498 7.05102C17.3403 7.44155 17.9735 7.44155 18.364 7.05102L19.0711 6.34392C19.4616 5.95339 19.4616 5.32023 19.0711 4.9297Z" fill="currentColor" />
                    <path d="M7.05037 16.9492C6.65984 16.5587 6.02668 16.5587 5.63615 16.9492L4.92905 17.6563C4.53852 18.0469 4.53852 18.68 4.92905 19.0706C5.31957 19.4611 5.95274 19.4611 6.34326 19.0706L7.05037 18.3634C7.44089 17.9729 7.44089 17.3398 7.05037 16.9492Z" fill="currentColor" />
                    <path d="M19.071 19.0703C18.6804 19.4609 18.0473 19.4609 17.6568 19.0703L16.9496 18.3632C16.5591 17.9727 16.5591 17.3395 16.9496 16.949C17.3402 16.5585 17.9733 16.5585 18.3639 16.949L19.071 17.6561C19.4615 18.0466 19.4615 18.6798 19.071 19.0703Z" fill="currentColor" />
                    <path d="M12 18.9999C11.4477 18.9999 11 19.4477 11 19.9999V20.9999C11 21.5522 11.4477 21.9999 12 21.9999C12.5523 21.9999 13 21.5522 13 20.9999V19.9999C13 19.4477 12.5523 18.9999 12 18.9999Z" fill="currentColor" />
                    <path d="M21 10.9999C21.5523 10.9999 22 11.4477 22 11.9999C22 12.5522 21.5523 12.9999 21 12.9999H20C19.4477 12.9999 19 12.5522 19 11.9999C19 11.4477 19.4477 10.9999 20 10.9999H21Z" fill="currentColor" />
                    <path d="M5 11.9999C5 11.4477 4.55228 10.9999 4 10.9999H3C2.44772 10.9999 2 11.4477 2 11.9999C2 12.5522 2.44772 12.9999 3 12.9999H4C4.55228 12.9999 5 12.5522 5 11.9999Z" fill="currentColor" />
                </svg>
            </SVGContainer>
        );
    }
}

export default withTheme(SunnyIcon);
