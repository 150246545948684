
import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ResponseList, SimModel, UserModel } from '../Redux/Models';

export default class ResellerService extends BaseService {

    public static getList(params: Object = {}, options?:Options): Promise<ResponseList<UserModel>> {
        let url:string = '/resellers'; 
        if(options?.root){
            url = options.root + url;
        }
        ResellerService.initCancelToken();
        if(options?.source){
            ResellerService.source =  options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: ResellerService.source?.token})
                .then(res => {
                    let data = new ResponseList<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: Object = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/resellers', params, {cancelToken:this.source?.token})
                .then(res => {
                    let data = new Response<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static update(id:string, params: Object = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch('/resellers/' + id, params, {cancelToken:this.source?.token})
                .then(res => {
                    let data = new Response<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static delete(id:string, params: Object = {}): Promise<Response<null>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.delete('/resellers/' + id, {params, cancelToken:this.source?.token})
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateStatus(id:string, params: Object = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch('/resellers/' + id + '/status', params , {cancelToken:this.source?.token})
                .then(res => {
                    let data = new Response<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }


    public static getById(id: String, params = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/resellers/'+ id, {params, cancelToken:this.source?.token}).then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
            .catch(err => {
                return reject(err);
            });
        });
    }

    public static addSims(id:String, params = {}, options:Options = {}): Promise<Response<SimModel[]>> {
        let url:string = '/resellers/' + id + '/sims'; 
        if(options?.root){
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post(url, params, {cancelToken:this.source?.token}).then(res => {
                let data = new Response<SimModel[]>(res?.data);
                return resolve(data);
            })
            .catch(err => {
                return reject(err);
            });
        });
    }
}