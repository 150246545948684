import { CompanyService } from "../../Services";
import { BaseStateModel, PriceModel, DataTableModel, UserModel, CompanyModel, NetworkModel } from "../Models";
import { VisibilityIcon } from '../../Helpers/Icons';

export class EditCompanyState extends BaseStateModel {
    public loading: boolean = false;
    public prices: Array<PriceModel> = [];
    public networks: Array<NetworkModel> = [];
    public companyModel: CompanyModel = {
        name: '',
        administrativeFirstName: '',
        administrativeLastName: '',
        administrativePhoneNumber: '',
        administrativeEmail: '',
        administrativeCountry: '',
        invoicingName: '',
        invoicingPhone: '',
        invoicingEmail: '',
        invoicingAddress: '',
        hqAddress: '',
        hqDeliveryAddress: '',
        hqPostalCode: '',
        hqCity: '',
        hqCountry: '',
        VAT: '',
        // plan:'',
        // cost: 0
    };
}

export class CompanyDetailState {
    public tab: number = 0;
    public user: UserModel = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    };
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'name', label: 'Name', isSort: true },
            { key: 'country', label: 'Country', isSort: true },
            { key: 'city', label: 'City', isSort: true },
            { key: 'postalCode', label: 'Postal Code', isSort: true },
        ]
    }
}

export class CompanyListState {
    public users: Array<UserModel> = [];
    public prices: Array<PriceModel> = [];
    public companies: Array<CompanyModel> = [];
    public noData: boolean = false;
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [
            { key: 'view', icon: VisibilityIcon },
        ],
        request: CompanyService.getList,
        headers: [
            { key: 'i', label: '#', isSort: false },
            { key: 'company.name', label: 'Name', isSort: true },
            { key: 'company.invoicingEmail', label: 'Email', isSort: true },
            { key: 'company.invoicingPhone', label: 'Phone Number', isSort: true },
        ]
    }
}
