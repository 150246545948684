
import { Response, AutomationRuleModel, Options, ResponseList } from '../Redux/Models';
import BaseService from './BaseService';
import { Http } from '../Http';

export default class BillingService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<AutomationRuleModel>> {
        BillingService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get("/credit-usage/company", { params })
                .then(res => {
                    let data = new ResponseList<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getListByCompany(id: string, params: Object = {}, options: Options = {}): Promise<ResponseList<AutomationRuleModel>> {
        BillingService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get("/credit-usage/" + id + "/company", { params, cancelToken: BillingService.source?.token })
                .then(res => {
                    let data = new ResponseList<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

}
