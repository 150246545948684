
import BaseService from './BaseService';
import { Http } from '../Http';
import { Response, ResponseList, CompanyModel, UserModel, Options, SimModel, NetworkModel } from '../Redux/Models';

export default class CompanyService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<UserModel>> {
        CompanyService.initCancelToken();
        let url: string = '/companies';
        if (options?.root) {
            url = options.root + url;
        }
        CompanyService.initCancelToken();
        if (options?.source) {
            CompanyService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: CompanyService.source?.token })
                .then(res => {
                    let data = new ResponseList<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: Object = {}, options: Options = {}): Promise<Response<CompanyModel>> {
        let url: string = '/companies';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post(url, params)
                .then(res => {
                    let data = new Response<CompanyModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static update(id: string, params: Object = {}, options: Options = {}): Promise<Response<CompanyModel>> {
        let url: string = '/companies/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch(url, params)
                .then(res => {
                    let data = new Response<CompanyModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static delete(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        let url: string = '/companies/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.delete(url, params)
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateStatus(id: string, params: Object = {}, options: Options = {}): Promise<Response<UserModel>> {
        let url: string = '/companies/' + id + '/status';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch(url, params)
                .then(res => {
                    let data = new Response<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }


    public static getById(id: String, params = {}, options: Options = {}): Promise<Response<UserModel>> {
        let url: string = '/companies/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(url, { params }).then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getBranchesByCompany(company: String, params = {}, options: Options = {}): Promise<ResponseList<UserModel>> {
        let url: string = '/companies/' + company + '/branches';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(url, { params }).then(res => {
                let data = new ResponseList<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static addSims(id: String, params = {}, options: Options = {}): Promise<Response<SimModel[]>> {
        let url: string = '/companies/' + id + '/sims';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post(url, params).then(res => {
                let data = new Response<SimModel[]>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getNetworks(): Promise<ResponseList<NetworkModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('networks').then(res => {
                let data = new ResponseList<NetworkModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}