import React, { Component } from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export class ProgressBanner extends Component {
    render() {
        return (
            <div className="progress-banner">
                <div>
                    <i className={`${this.props.icon} white`}></i>
                    <p className="lead white mt-3">{this.props.text.big}</p>
                    <p className="small white mt-2">{this.props.text.small}</p>
                </div>
                <div className="progress-part">
                    <CircularProgressbar value={this.props.value} maxValue={this.props.max}
                        strokeWidth={4} text={`${this.props.value}/${this.props.max}`}
                        styles={buildStyles({
                            // strokeLinecap: "butt", pathColor: variables.white,
                            // textColor: variables.white, trailColor: variables.grey
                        })} />;
                </div>
            </div>
        )
    }
}

export default ProgressBanner
