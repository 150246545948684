import { PaymentService } from "../../Services";
import { RechargeModel, BaseStateModel, DataTableModel, paymentModel } from "../Models";
import { ReceiptIcon } from '../../Helpers/Icons';
export class RechargeState extends BaseStateModel {
    public loading: boolean = false;
    public rechargeModel: RechargeModel = {
        amount: '',
        method: '',
        charge: '',
        vat: '',
        total_amount: ''
    }
    public payment_methods: string[] = [];
    public clientSecret: string = "";
    public show_paypal: boolean = false;
}

export class PaymentListState {
    public payments: Array<paymentModel> = [];
    public noData: boolean = false;
    public params = {
        total: 0,
        page: 0,
        limit: 10,
        active: '_id',
        direction: 'asc',
        q: '',
    }
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actionWidth: '10%',
        // actions: [],
        request: () => { },
        headers: [
            { key: 'i', label: '#', isSort: false },
            {
                key: 'amount', label: 'Amount', isSort: true, render: (item: any) => {
                    const n = item.amount / 100;
                    return "€ " + n;
                }
            },
            {
                key: 'createdAt', label: 'Date', isSort: true, render: (item: any) => {
                    var date = item.createdAt.substring(0, 10);
                    var time = item.createdAt.substring(11, 19);
                    var dateArray = date.split('-');
                    return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0] + ' ' + time;
                }
            },
            { key: 'status', label: 'Status', isSort: true, },
        ]
    }
}