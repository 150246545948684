import { BaseModel } from "./Base.Models";

export class PermissionModel extends BaseModel {
	public name: string;
	public status: boolean;
}

export class RoleModel extends BaseModel {
	public name: string;
	public status?: boolean;
}

export class ModuleModel extends BaseModel {
	public name: string;
	public status: boolean;
	public permissions: Array<PermissionModel | string>;
}

export class ModulePermissionModel extends BaseModel {
	public user?: string;
	public role: string;
	public module: string;
	public permissions: Array<PermissionModel | string>;
	public status?: boolean;
}