import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'

export class PencilIcon extends Component<Props, States> {
    static defaultProps = {
        width: 15,
        height: 14
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.colors.textColor;

        return (
            <SVGContainer {...restProps}>
                <svg width={width} height={height} fill={color} viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5889 1.72117C9.80408 0.584786 11.7743 0.584806 12.9894 1.72122L13.5786 2.27225C14.7938 3.40864 14.7937 5.25103 13.5786 6.38738L6.92815 12.6065C6.5412 12.9683 6.05905 13.2283 5.53033 13.3603L3.42794 13.885C1.69422 14.3178 0.108692 12.8351 0.571349 11.2138L1.1324 9.24762C1.27351 8.75313 1.55158 8.30219 1.93857 7.9403L8.5889 1.72117ZM11.8893 2.74998C11.2817 2.18178 10.2966 2.18177 9.68902 2.74996L8.98006 3.41295L11.7695 6.02154L12.4785 5.35858C13.086 4.79041 13.0861 3.86921 12.4785 3.30102L11.8893 2.74998ZM4.31265 7.77773L7.87994 4.44174L10.6694 7.05032L7.10206 10.3863L4.31265 7.77773ZM3.21253 8.80652L3.03869 8.96909C2.84519 9.15003 2.70616 9.3755 2.6356 9.62275L2.07455 11.5889C1.92033 12.1293 2.44884 12.6235 3.02675 12.4793L5.12913 11.9546C5.39349 11.8886 5.63457 11.7586 5.82805 11.5777L6.00193 11.4151L3.21253 8.80652Z" fill="currentColor" fill-opacity="0.5" />
                </svg>
            </SVGContainer>
        );
    }
}

export default withTheme(PencilIcon);
