import React, { Component } from 'react';
import { SVGContainer } from './style';
import { Props, States } from '../../Redux/Models';
import { withTheme } from 'styled-components'

export class UserIcon extends Component<Props, States> {
    static defaultProps = {
        width: 24,
        height: 24
    };

    render(): React.ReactElement {
        const { width, height, theme, ...restProps } = this.props;
        const color = theme.colors.textColor;

        return (
            <SVGContainer {...restProps}>
                <svg width={width} height={height} fill={color} viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5714 20.6899C9.50034 20.6899 7.82141 22.3689 7.82141 24.4399C7.82141 25.1303 8.38106 25.6899 9.07141 25.6899H21.5714C22.2618 25.6899 22.8214 25.1303 22.8214 24.4399C22.8214 22.3689 21.1425 20.6899 19.0714 20.6899H11.5714ZM5.32141 24.4399C5.32141 20.9882 8.11963 18.1899 11.5714 18.1899H19.0714C22.5232 18.1899 25.3214 20.9882 25.3214 24.4399C25.3214 26.511 23.6425 28.1899 21.5714 28.1899H9.07141C7.00034 28.1899 5.32141 26.511 5.32141 24.4399Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3214 5.68994C13.2503 5.68994 11.5714 7.36887 11.5714 9.43994C11.5714 11.511 13.2503 13.1899 15.3214 13.1899C17.3925 13.1899 19.0714 11.511 19.0714 9.43994C19.0714 7.36887 17.3925 5.68994 15.3214 5.68994ZM9.07141 9.43994C9.07141 5.98816 11.8696 3.18994 15.3214 3.18994C18.7732 3.18994 21.5714 5.98816 21.5714 9.43994C21.5714 12.8917 18.7732 15.6899 15.3214 15.6899C11.8696 15.6899 9.07141 12.8917 9.07141 9.43994Z" fill="currentColor" />
                </svg>

            </SVGContainer>
        );
    }
}

export default withTheme(UserIcon);
