import Reducers from './Reducers';
import Stroage from './Stroage';
import { createStore , applyMiddleware} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { StateModel } from './Models/Base.Models';

const initialState:StateModel = Stroage.LoadState();

const Store = createStore(
    Reducers,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
);

Store.subscribe(() => {
    Stroage.SaveState(Store.getState());
});

export default Store;