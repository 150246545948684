import { BaseModel } from './Base.Models';


export class DataUsageTrendReportModel extends BaseModel {
    name: string;
    type: string;
    event?: string;
    value?: string;
    action: string;
    followUpAction?: string;
    status: boolean;
    group?: Array<string>;
    tags?: Array<string>;
    user: Array<string>;
}